<div id="cover-spin" *ngIf="isLoading"></div>
<div class="personalised-container">
  <div class="personalised-inner-container">
    <div class="personalised-banner">
      <img [src]="personalisDetails?.customer_image" alt="Hotal Image" class="banner" loading="lazy">
      <div class="personalised-detail">
        <div class="img-wrapper">
          <img [src]="personalisDetails?.customer_logo" alt="logo" loading="lazy">
        </div>
        <h3>{{personalisDetails?.hotel_name}}</h3>
        <p>{{personalisDetails?.address}}</p>
        <div class="personalised-section">
          <div class="personalised-index">
            <div class="personalised-area">
              <i [innerHTML]="icons['greenaLogoIcon'] | safe"></i>
            </div>
            <div class="personalised-desc">
              <h5>Your estimated Greena Index: {{personalisDetails?.estimated_greena_index}}/100</h5>
            </div>
          </div>
          <div class="calculated">
            <div class="calculated-desc">
              <h5 [ngbTooltip]="tipContent" tooltipClass="custom-tooltip" container="body"
              placement="bottom-left bottom top-left top-right bottom-right right"
               #t="ngbTooltip" (mouseenter)="t.open()"
              triggers="click:blur" [autoClose]="false" #calButton (click)="onCalcuClick()">How was this calculated?</h5>
              <ng-template #tipContent>
                <div class="tooltip-wrap personalised-tooltip" (click)="onPopupClick($event)">
                  <i [innerHTML]="icons['info'] | safe"></i>
                  <div class="tooltip-content">
                    <p>The Greena World team has developed innovative technology that combines data from thousands of
                      sources to estimate the level of positive impact a {{personalisDetails?.business_name | lowercase}} has on the planet and its local
                      community. Specifically, {{personalisDetails?.hotel_name}}'s Greena Index was generated using:</p>
                    <ul>
                      <li><b>{{personalisDetails?.feedback_points}}</b> feedback points from your guests across <b>7</b> different booking platforms</li>
                      <li><b>{{personalisDetails?.database_count}}</b> public databases, including both government and private sources</li>
                    </ul>
                    <p>The final step is for you to <a href="/auth/signup" target="_blank">add your own data</a>, which will refine your Greena
                      Index and help you better communicate your positive impact to both current and prospective guests.
                    </p>
                    <p>Discover the benefits of obtaining your Greena Index <a href="/benefits" target="_blank">here.</a></p>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="environment">
          <i [innerHTML]="icons['info_icon'] | safe"></i>
          <p>Greena Index reflects your level of positive impact on the environment, your local community and your
            guests' wellbeing. <a (click)="gotoOtherPage('/greena-index')">Learn more.</a></p>
        </div>
        <div class="feedback-btn" (click)="gotoOtherPage('/auth/signup')">
          <h3><span>Provide Feedback for Your Precise Greena Index</span> <i
              [innerHTML]="icons['arrowLong'] | safe"></i></h3>
        </div>
      </div>
    </div>
    <div class="why-line">
        <h4 class="mb-0">Why?</h4>
    </div>
    <div class="env-section">
      <div class="env-res-text">
        "Environmental and social responsibility matters to <span>86%</span> of
        guests. <br />
        <span>33%</span> actively check sustainability practices before booking."
      </div>
      <div class="hilton-wrap">
        <a href="https://cr.hilton.com/wp-content/uploads/2019/05/Hilton-Corporate-Responsibility-2018.pdf"
          target="_blank">
          <img src="assets/img/svg/hilton.svg" alt="hilton" loading="lazy"/>
        </a>
        <p>Research with 73,000 participants.</p>
      </div>
      <div class="rapid-wrapper">
        <div class="left_wrap" (click)="gotoOtherPage('/benefits')">
          <i [innerHTML]="icons['star'] | safe"></i>
          <h3>
            Discover Additional Benefits of the Greena Index
          </h3>
          <i [innerHTML]="icons['arrowOutline'] | safe"></i>
        </div>
      </div>
    </div>
    <div class="greena-path-wrapper">
      <div class="path-inner-wrapper">
        <div class="top-wrapper" id="start-auto-scroll">
          <h4 class="text-primary">
            Don't Keep Your Positive Impact a Secret: Let Us Help You Share It
            with Prospective Guests
          </h4>
          <img src="../../assets/img/positive_impact.jpg" alt="positive_impact" loading="lazy"/>
        </div>
      </div>
      <div class="personalised-guests">
        <app-prospective-guests [isPersonalized]="true"></app-prospective-guests>
      </div>
      <div class="next-section">
        <div class="row justify-content-center flex-column-reverse flex-md-row">
          <div class="col-md-8 col-sm-12 col-12">
            <h2 class="mb-1">Next Steps:</h2>
            <p class="desc">Upon providing your input, the following will be provided to you free of charge:</p>
            <ul>
              <li *ngFor="let step of stepList">{{step}}</li>
            </ul>
            <button class="btn btn-primary" (click)="gotoOtherPage('/auth/signup')">Get it now<i [innerHTML]="icons['arrowLong'] | safe"></i></button>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <div class="img-wrapper">
              <img src="../../../assets/img/epc-book.png" alt="epc-book" loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
      <div class="benefit-section">
        <div class="benefit">
          <i [innerHTML]="icons['globeHemisphere'] | safe"></i>
          <h2>Benefit more from your existing positive impact on the environment and local communities.</h2>
        </div>
        <div class="benefit-btn" (click)="gotoOtherPage('/auth/signup')">
          <p>Provide Feedback for Your Precise Greena Index</p> <i [innerHTML]="icons['arrowLong'] | safe"></i>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>