import { Router } from "@angular/router";
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { icons } from "src/assets/img/svg/svg";
import { OwlOptions } from "ngx-owl-carousel-o";
@Component({
  selector: "app-organization",
  templateUrl: "./organization.component.html",
  styleUrls: ["./organization.component.scss"],
})
export class OrganizationComponent implements OnInit, AfterViewInit {
  
  public icon = icons;
  @Input() networksList:any;
  public isLoaded:boolean = false;


  logoSlider: OwlOptions = {
    dots: false,
    nav: false,
    autoWidth: true,
    loop: true,
    autoplay: true,
    autoplayTimeout: 1000,
    responsiveRefreshRate: 500,
    items: 6,
    margin: 10,
    responsive: {
      0: {
        margin: 37,
        items: 4,
      },
      600: {
        margin: 20,
        items: 4,
      },
      1024: {
        margin: 88,
        items: 6,
      },
    },
  };

  constructor(private router:Router) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isLoaded = true;
    }, 1000);
  }

  public onSignup(){
    this.router.navigate(['/auth/signup']);
  }
}
