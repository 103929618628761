import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor() { }

  removeKey(key: string) {
    if(typeof window !== 'undefined'){
      localStorage.removeItem(key);
    }
  }

  setLocalStorage(key:string, value:any){
    if(typeof window !== 'undefined'){
      localStorage.setItem(key,value);
    }
  }

  getLocalStorage(key:string){
    if(typeof window !== 'undefined'){
     const value = localStorage.getItem(key);
     return value;
    } else {
      return '';
    }
  }
}
