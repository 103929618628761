import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HomeRoutingModule } from "./home-routing.module";
import { HomeComponent } from "./home.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SharedModule } from "../shared/shared.module";
import { ContactUsModule } from "../contact-us/contact-us.module";
import { TranslocoRootModule } from "../transloco-root.module";
import { CoreModule } from "../core/core.module";
import { ToastModule } from "primeng/toast";
import { GlxPageComponent } from "./glx-page/glx-page.component";
import { DialogModule } from "primeng/dialog";
import { PipeModule } from "../shared/pipe/pipe.module";
import { NgbTooltip, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [HomeComponent, GlxPageComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    CarouselModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    DialogModule,
    ContactUsModule,
    CoreModule,
    ToastModule,
    PipeModule,
    NgbTooltipModule
  ],
  exports: [HomeComponent],
  providers:[NgbTooltip]
})
export class HomeModule {}
