import { BioGraphRes, Endorsers, TermsCondition } from './../_interfaces/survey.model';
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { of, throwError as observableThrowError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { advisorRes, glxEndorsersRes, privacyPolicyData } from "../_interfaces/survey.model";
@Injectable({
  providedIn: "root",
})
export class SurveyService {
  baseUrl: any = environment.baseURL;
  selectedLanguage = new BehaviorSubject("en");
  language!: string;
  public surveyData: Array<any> = [];
  dialogCloseSubject: BehaviorSubject<boolean>;
  dialogClose: Observable<any>;
  dialogOpenSubject: BehaviorSubject<boolean>;
  dialogOpen: Observable<any>;
  paperOpenSubject: BehaviorSubject<boolean>;
  paymentOpenSubject: BehaviorSubject<boolean>;
  showPricing: BehaviorSubject<boolean>;
  showPricingObs: Observable<any>;
  paperOpen: Observable<any>;

  constructor(private http: HttpClient) {
    this.dialogCloseSubject = new BehaviorSubject<boolean>(false);
    this.dialogClose = this.dialogCloseSubject.asObservable();
    this.dialogOpenSubject = new BehaviorSubject<boolean>(false);
    this.dialogOpen = this.dialogCloseSubject.asObservable();
    this.paperOpenSubject = new BehaviorSubject<boolean>(false);
    this.paymentOpenSubject = new BehaviorSubject<boolean>(false);
    this.showPricing = new BehaviorSubject<boolean>(false);
    this.showPricingObs = this.showPricing.asObservable();
    this.paperOpen = this.paperOpenSubject.asObservable();

    this.selectedLanguage.subscribe((response) => {
      this.language = response;
      if (response) {
        this.language = response;
      } else {
        this.language = localStorage.getItem("langauge") as string;
      }
    });
  }

  private handleError(error: HttpErrorResponse) {
    return observableThrowError(error || "Server error");
  }

  generateUUID(): Observable<any> {
    return this.http.post<any>(this.baseUrl + "survey/", "").pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }
  getCartAvailability(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "survey/greena-card-check/").pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getAnswersDetails(id: string) {
    return this.http.get<any>(this.baseUrl + "survey/" + id + "/").pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  addAnswer(data: any, id: string): Observable<any> {
    return this.http
      .put<any>(this.baseUrl + "survey/" + id + "/answer/", data)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  saveContactData(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + "survey/contact-us/", data).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  savePaperRequestData(paperFormData: any): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + "vendor-survey/paper-request/", paperFormData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  submitForm(data: any, id: string): Observable<any> {
    return this.http
      .put<any>(this.baseUrl + "survey/" + id + "/signup/", data)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  getContactInfo(): Observable<any> {
    return this.http
      .get<any>(
        this.baseUrl + "survey/" + this.language + "/contact-inquiry-view/"
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getBioInfo(): Observable<BioGraphRes> {
    return this.http
      .get<BioGraphRes>(this.baseUrl + "survey/" + this.language + "/bio-text/")
      .pipe(
        map((res: BioGraphRes) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getGlxFrontText(): Observable<any> {
    return this.http
      .get<any>(this.baseUrl + "survey/" + this.language + "/gix-front-text/")
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getGlxEndorsed(): Observable<glxEndorsersRes[]> {
    return this.http
      .get<glxEndorsersRes[]>(this.baseUrl + "survey/" + this.language + "/gix-endorsers/")
      .pipe(
        map((res: glxEndorsersRes[]) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getPrivacyPolicy(): Observable<privacyPolicyData[]> {
    return this.http
      .get<privacyPolicyData[]>(this.baseUrl + "survey/" + this.language + "/privacy-policy/")
      .pipe(
        map((res: privacyPolicyData[]) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getAdvisor(): Observable<advisorRes[]> {
    return this.http.get<advisorRes[]>(this.baseUrl + "survey/advisors/").pipe(
      map((res: advisorRes[]) => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  public getTermsCondition(): Observable<TermsCondition[]>{
    return this.http.get<TermsCondition[]>(this.baseUrl + "survey/" + this.language + "/terms-and-conditions/")
    .pipe(
      map((res: TermsCondition[]) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  public getTermsEngagement(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}setting/terms-of-engagement/`);
  }
}
