import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GlxPageComponent } from "./glx-page/glx-page.component";
import { HomeComponent } from "./home.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  {
    path: "greena-index",
    component: GlxPageComponent,
  },
  { path: "en", component: HomeComponent },
  { path: "de", component: HomeComponent },
  { path: "es", component: HomeComponent },
  { path: "it", component: HomeComponent },
  { path: "sv", component: HomeComponent },
  { path: "ru", component: HomeComponent },
  { path: "fr", component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
