import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/payment/service/payment.service';
import { CommonService } from 'src/app/services/common.service';
import { icons } from 'src/assets/img/svg/svg';

@Component({
  selector: 'app-personalized-guide',
  templateUrl: './personalized-guide.component.html',
  styleUrls: ['./personalized-guide.component.scss']
})
export class PersonalizedGuideComponent implements OnInit, OnChanges {

  public icons = icons;
  public isSuccess:boolean = false;
  @Input() vendorId!:string;
  public popUpMessage:any;
  private isClickHere:boolean = false;

  constructor(
    public router: Router,
    private paymentService: PaymentService,
    private commonService : CommonService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.vendorId && changes.vendorId.currentValue){
      this.vendorId = changes.vendorId.currentValue;
    }
    this.popUpMessage = {
      message: `To allow our experts to begin crafting your sustainability feedback and roadmap, please set up your subscription`,
      url:`/payment/subscription/${this.vendorId}`,
      borderColor: '#1e88e5',
      icon: this.icons['clock'],
      closeIcon: this.icons['close_icon'],
    };
  }

  getClickEvent(event: any) {
    this.isSuccess = false;
    this.isClickHere = event;
  }

  getCloseEvent(event:any){
    this.isSuccess = !this.isSuccess;
  }

  public showNotification(){
    this.isSuccess = !this.isSuccess;
    // setTimeout(() => {
    //   this.isSuccess = false;
    //   // this.paymentService.checkActivePlan(this.vendorId);
    //   if(!this.isClickHere){
    //     this.commonService.gotoNewTab(`/payment/subscription/${this.vendorId}`);
    //   }
    // }, 5000);
  }
}
