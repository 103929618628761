import { CommonService } from './../../services/common.service';
import { VendorsService } from 'src/app/services/vendors.service';
import { Component, OnInit, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { OwlOptions } from "ngx-owl-carousel-o";
import { UtilityService } from "src/app/services/utility.service";
import { Subscription } from "rxjs/internal/Subscription";
import { icons } from 'src/assets/img/svg/svg';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
@Component({
  selector: "app-how-sections",
  templateUrl: "./how-sections.component.html",
  styleUrls: ["./how-sections.component.scss"],
  host: {
    "(window:resize)": "onWindowResize($event)",
  },
})
export class HowSectionsComponent implements OnInit, AfterViewInit {
  @Output() signup = new EventEmitter<any>();
  isOpenMenu: boolean = false;
  displayPaper: boolean = false;
  public browserType!: string;
  showForm: boolean = false;
  isVendor: boolean = false;
  isTraveller: boolean = false;
  isSurvey: boolean = false;
  isGlx: boolean = false;
  uuid: string = "";
  cxOneValue!: string;
  cyOneValue!: string;
  radiusOneValue!: string;
  cxValue!: string;
  cyValue!: string;
  radiusValue!: string;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  featureList: any;
  activeShowRoomData: Array<any> = [];
  progresSlider!: OwlOptions;
  private subscriptions: Subscription[] = [];
  isLoading:boolean = false;

  // howSlider: OwlOptions = {
  //   navText: [
  //     '<i class="bi bi-chevron-left"></i>',
  //     '<i class="bi bi-chevron-right"></i>',
  //   ],
  //   responsive: {
  //     0: {
  //       margin: 20,
  //       items: 2,
  //       touchDrag: true,
  //       mouseDrag: true,
  //       loop: true,
  //       nav: true,
  //       dots: true,
  //     },
  //     600: {
  //       margin: 20,
  //       items: 2,
  //       touchDrag: true,
  //       mouseDrag: true,
  //       loop: true,
  //       nav: true,
  //       dots: true,
  //     },
  //     768: {
  //       margin: 20,
  //       items: 3,
  //       touchDrag: true,
  //       mouseDrag: true,
  //       loop: true,
  //       nav: true,
  //       dots: true,
  //     },
  //     1024: {
  //       margin: 20,
  //       items: 4,
  //       touchDrag: false,
  //       mouseDrag: false,
  //       loop: false,
  //       nav: false,
  //       dots: false,
  //     },
  //   },
  // };

  public icon = icons;
  // @ViewChildren('t') childTemp!:NgbTooltip;
  private data:any;

  constructor(
    private router: Router,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private vendorsService: VendorsService,
    private commonService: CommonService,
    private tooltipConfig:NgbTooltip
  ) { }

  ngOnInit(): void {
    this.browserType = this.utilityService.detectBrowser();
    if (this.router.url === "/vendors") {
      this.isVendor = true;
    } else if (this.router.url === "/eco-travel") {
      this.isTraveller = true;
    } else if (this.router.url === "/greena-index") {
      this.isGlx = true;
    } else {
      this.isSurvey = true;
    }

    this.progressValues();

    this.getShowRoomData();
    const routerSubscription = this.route?.queryParams.subscribe((value) => {
      let queryParams = value["about"];
      if (queryParams === "why") {
        this.scrollToId("why");
      }
    });
    this.subscriptions.push(routerSubscription);
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params.isGreena) {
        setTimeout(() => {
          this.commonService.scrollTop("greenaIndex");
        }, 1300);
      }
    });
  }

  onWindowResize(event: any) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    this.progressValues();
  }

  progressValues() {
    this.progresSlider = {
      responsiveRefreshRate: 500,
      navText: [
        '<i class="bi bi-chevron-left"></i>',
        '<i class="bi bi-chevron-right"></i>',
      ],
      responsive: {
        0: {
          margin: 0,
          items: 1,
          touchDrag: true,
          mouseDrag: true,
          loop: true,
          nav: true,
          dots: true,
        },
        325: {
          margin: 10,
          items: 2,
          touchDrag: true,
          mouseDrag: true,
          loop: true,
          nav: true,
          dots: true,
        },
        600: {
          margin: 20,
          items: 2,
          touchDrag: true,
          mouseDrag: true,
          loop: true,
          nav: true,
          dots: true,
        },
        1000: {
          margin: 20,
          items: 3,
          touchDrag: false,
          mouseDrag: false,
          loop: false,
          nav: true,
          dots: false,
        },
        1920: {
          items: 3,
        }
      },
    };
    if(typeof window !== 'undefined'){
      if (window.innerWidth > 1500) {
        this.cxOneValue = "50";
        this.cyOneValue = "50";
        this.radiusOneValue = "40";
        this.cxValue = "50";
        this.cyValue = "50";
        this.radiusValue = "40";
      }
      else if (window.innerWidth < 1500 && window.innerWidth > 576) {
        this.cxOneValue = "35";
        this.cyOneValue = "35";
        this.radiusOneValue = "27";
        this.cxValue = "35";
        this.cyValue = "35";
        this.radiusValue = "27";
      }
      else if (window.innerWidth < 576) {
        this.cxOneValue = "24";
        this.cyOneValue = "24";
        this.radiusOneValue = "20";
        this.cxValue = "24";
        this.cyValue = "24";
        this.radiusValue = "20";
      }
    }
  }

  scrollToId(id: string) {
    const data: HTMLElement | null = document.getElementById(id);
    setTimeout(() => {
      data?.scrollIntoView({
        behavior: "smooth",
      });
    }, 800);
  }

  signUp(value: string) {
    if(typeof window !== 'undefined'){
      window.scrollTo(0, 0);
      this.signup.next(value);
    }
  }

  signUpVendor() {
    let vendorUUID = localStorage.getItem("vendorUUID");
    // localStorage.setItem("CalculateGreenaIndedxFrom", this.router.url);
    if (vendorUUID) {
      this.getVendorSignup(vendorUUID);
    } else {
      this.generateVendorUUID();
    }
  }

  getVendorSignup(vendorId: string) {
    this.router.navigate(["vendors/info/" + vendorId]);
  }

  generateVendorUUID(): void {
    const sb = this.vendorsService.generateVendorUUID().subscribe((res) => {
      if (res) {
        this.uuid = res.UUID;
        localStorage.setItem("vendorUUID", this.uuid);
        this.router.navigate(["vendors/info/" + this.uuid]);

      }
    });
    this.subscriptions.push(sb);
  }

  getShowRoomData() {
    this.isLoading = true;
    this.commonService.getShowRoom().subscribe((res: any) => {
      if (res) {
        res.map((value: any) => {
          value.business.business_name = value.business.business_name.includes('Accommodation') ? 'Hotel' : 'Restaurant';
        });
        this.activeShowRoomData = res.filter((element: any) => element.is_active === true);
      }
      this.isLoading = false;
    },
    (err:any)=>{
      this.isLoading = false;
    });
  }

  tooltipArrow() {
    var $button = document.querySelectorAll(".dropdown-menu");
    if ($button[0]?.getAttribute("data-popper-placement") == "top-end") {
      $button[0]?.parentElement?.classList?.add('bottom-arrow')
    }
    else {
      $button[0]?.parentElement?.classList?.remove('bottom-arrow')
    }
  }

  public onMouseEnter(tooltip: NgbTooltip) {
    let isMobile;
    if (typeof window !== "undefined") {
      isMobile = window.innerWidth <= 768
    }
    if (!isMobile) {
      this.tooltipConfig.triggers = "manual";
      if (this.data && this.data.isOpen()) {
        this.data.close();
      }
      this.data = tooltip;
      tooltip.open();
    }
  }

  public onMouseLeave(tooltip: NgbTooltip) {
    tooltip.close();
  }
}
