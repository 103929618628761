import { SurveyService } from "src/app/services/survey.service";
import { Component, OnInit } from "@angular/core";
import { privacyPolicyData } from "../_interfaces/survey.model";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
  host: {
    "(window:resize)": "onWindowResize($event)",
  },
})
export class PrivacyPolicyComponent implements OnInit {
  public tableTitle: string = "Table of contents";
  public isOpenMenu: boolean = true;
  public selectedName!: string;
  public windowWidth = window.innerWidth;
  public activeTab: number = 1;
  public tableContentlist: Array<privacyPolicyData> = [];
  language!: string;

  constructor(private surveyService: SurveyService) {
    this.surveyService.selectedLanguage.subscribe((response) => {
      this.language = response;
      if (response) {
        this.language = response;
      } else {
        this.language = localStorage.getItem("langauge") as string;
      }
    });
  }

  ngOnInit(): void {
    this.getPrivacyPolicyData();
  }

  getPrivacyPolicyData() {
    this.surveyService.getPrivacyPolicy().subscribe((res:privacyPolicyData[]) => {
      this.tableContentlist = res;
          });
  }

  onWindowResize(event: any) {
    this.windowWidth = event.target.innerWidth;
    if (this.windowWidth < 768) {
      if (this.selectedName) {
        this.tableTitle = this.selectedName;
      } else {
        this.tableTitle = "Table of contents";
      }
    } else {
      this.tableTitle = "Table of contents";
    }
  }

  public openDropdown(): void {
    if (this.windowWidth < 768) {
      this.isOpenMenu = !this.isOpenMenu;
    } else {
      this.isOpenMenu = true;
    }
  }

  moveToContent(id: number, section_heading: string = "") {
    const data: HTMLElement | null = document.getElementById(section_heading);
    setTimeout(() => {
      data?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100);

    this.tableContentlist.forEach((element) => {
      if (element.id === id) {
        if (this.windowWidth < 768) {
          this.tableTitle = element.section_heading;
        }
        this.selectedName = this.tableTitle;
      }
    });

    this.activeTab = id;
    this.isOpenMenu = true;
    document.getElementsByTagName("HTML")[0].classList.remove("hideScroll");
  }
}
