import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { SurveyService } from "src/app/services/survey.service";
import { greenaContactInfo } from "src/app/_interfaces/contact.model";
import { icons } from "src/assets/img/svg/svg";
import { FooterDetails } from "src/app/_interfaces/common.model";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {

  public icon = icons;
  public currentYear!:number;

  address: string = "";
  email: string = "";
  phone: string = "";
  facebook: string = "";
  twitter: string = "";
  linkedin: string = "";
  instragram: string = "";
  // currentTab: number = 0;
  // isOpenMenu: boolean = false;
  // displayContact: boolean = false;
  // isTeam: boolean = false;
  // isGlx: boolean = false;
  // isPrivacy: boolean = false;
  // isTravel: boolean = false;
  // venderId!: string;
  // isDashboard: boolean = false;
  private subscriptions: Subscription[] = [];

  public displayContact: boolean = false;

  public footerDatas:FooterDetails[]=[
    {
      title:'Clients',
      option:[
        // {
        //   lable:'Hotels & restaurants',
        //   link:'/'
        // },
        {
          lable:'Benefits',
          link:'/benefits'
        },
        // {
        //   lable:'Pricing',
        //   link:'/pricing'
        // },
        {
          lable:'Verification',
          link:'/verification'
        },
      ]
    },
    {
      title:'Personal',
      option:[
        {
          lable:'About Us',
          link:'/about-us'
        },
        {
          lable:'Greena Index',
          link:'/greena-index'
        },
        {
          lable:'Blog',
          link:'/blog'
        },
      ]
    } 
  ];

  constructor(
    private surveyService: SurveyService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.route.queryParams.subscribe((res: any) => {
      if (res.contact === 'true' && typeof window !== "undefined") {
       this.open();
      }
    });
  }

  ngOnInit(): void {
    this.getContactInfo();
    this.currentYear = new Date().getFullYear();
    // if (this.router.url === "/greena-team") {
    //   this.isTeam = true;
    // } else if (this.router.url === "/vendors/dashboard/" + this.venderId) {
    //   this.isDashboard = true;
    // } else if (this.router.url === "/greena-index") {
    //   this.isGlx = true;
    // } else if (this.router.url === "/privacy-policy") {
    //   this.isPrivacy = true;
    // } else if (this.router.url === "/eco-travel") {
    //   this.isTravel = true;
    // }
    // const routerSubscription = this.route?.queryParams.subscribe((value) => {
    //   let queryParams = value["about"];
    //   if (queryParams === "how") {
    //     this.scrollToId("how");
    //   } else if (queryParams === "benefits") {
    //     this.scrollToId("benefits");
    //   } else if (queryParams === "movement") {
    //     this.scrollToId("movement");
    //   }
    // });
    // this.subscriptions.push(routerSubscription);
  }

  // scrollToId(id: string) {
  //   const data: HTMLElement | null = document.getElementById(id);
  //   setTimeout(() => {
  //     data?.scrollIntoView({
  //       behavior: "smooth",
  //     });
  //   }, 500);
  // }

  getContactInfo() {
    this.surveyService
      .getContactInfo()
      .subscribe((res: greenaContactInfo[]) => {
        if (res) {
          res.forEach((element: greenaContactInfo) => {
            this.address = element.address;
            this.email = element.contact_inquiry_emails;
            this.phone = element.footer_contact_number;
            this.facebook = element.facebook;
            this.instragram = element.instagram;
            this.linkedin = element.linkedin;
            this.twitter = element.twitter;
            this.modifyFooter(element.is_pricing);
            this.surveyService.showPricing.next(element.is_pricing);
          });
        }
      });
  }

  private modifyFooter(is_pricing: boolean) {
    if (!is_pricing) {
      this.footerDatas[0].option.push({ lable: 'Pricing', link: '/pricing' });
    } else {
      this.footerDatas[0].option.unshift({ lable: 'Home', link: '/' });
    }
  }

  public open() {
    this.displayContact = true;
    if (this.router.url.includes('payment') || this.router.url.includes('vendors')) {
      this.surveyService.paymentOpenSubject.next(true);
    } else {
      this.surveyService.paperOpenSubject.next(false);
    }
  }

  public close() {
    this.surveyService.dialogCloseSubject.next(true);
  }

  // activeTab(id: number, scrollId: string = "") {
  //   const data: HTMLElement | null = document.getElementById(scrollId);

  //   setTimeout(() => {
  //     data?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //       inline: "nearest",
  //     });
  //   }, 100);

  //   this.currentTab = id;
  //   this.isOpenMenu = false;
  //   document.getElementsByTagName("HTML")[0].classList.remove("hideScroll");
  // }

  // contactForm() {
  //   this.displayContact = true;
  //   this.surveyService.paperOpenSubject.next(false);
  // }

  // close() {
  //   this.surveyService.dialogCloseSubject.next(true);
  // }
}
