import { Subscription } from "rxjs";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SurveyService } from "../services/survey.service";
import { contactInfo } from "../_interfaces/contact.model";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { SlideInOutAnimation } from "./animation";
@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
  animations: [SlideInOutAnimation],
  providers: [MessageService],
})
export class ContactUsComponent implements OnInit {
  @Input() showForm!: boolean;
  paperShow: boolean = true;
  paymentShow: boolean = false;
  contactForm: FormGroup;
  isOtherOption: boolean = false;
  isDisabled: boolean = false;
  isClicked: boolean = false;
  submitted: boolean = false;
  formSubmitted: boolean = false;
  isOpenMenu: boolean = false;
  emailEnabled: boolean = false;
  data: Array<contactInfo> = [];
  //countryCodeList: Array<any> = [];
  customAnswer: string = "";
  identityName: string = "";
  selectedAnswer: string = "";
  errorMessage: string = "";
  commonErrorMessage: any;
  //selectedCode: string;
  animationState = "out";
  paperRequestForm: FormGroup;
  private subscriptions: Subscription[] = [];
  public submitLoader: boolean = false;
  private currentURL!:string;

  dataList: contactInfo[] = [
    {
      id: 0,
      name: "Hotel or restaurant",
      isSelected: false,
      option: "E",
    },
    {
      id: 1,
      name: "Eco-conscious traveler",
      isSelected: false,
      option: "A",
    },
    {
      id: 2,
      name: "Expert in ecology and sustainability",
      isSelected: false,
      option: "B",
    },
    {
      id: 3,
      name: "Expert in hospitality-related businesses",
      isSelected: false,
      option: "C",
    },
    {
      id: 4,
      name: "Talent willing to join the team",
      isSelected: false,
      option: "D",
    },
    // { id: 4, name: "Investor", isSelected: false, option: "E" },
  ];

  constructor(
    private fb: FormBuilder,
    private surveyService: SurveyService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.contactForm = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      message: [""],
    });
    this.selectedAnswer = "Select Option";

    this.paperRequestForm = this.fb.group({
      nameSurname: ["", Validators.required],
      organization: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      job: ["", Validators.required],
    });

    const paperDialogSubscription =
      this.surveyService.paperOpenSubject.subscribe((isOpen: boolean) => {
        if (isOpen) {
          this.paymentShow = false;
          this.paperShow = true;
          this.paperRequestForm.reset();
        } else {
          this.paperShow = false;
          this.paymentShow = false;
        };
      });
    this.subscriptions.push(paperDialogSubscription);
    const paymentDialogSubscription =
    this.surveyService.paymentOpenSubject.subscribe((isOpen: boolean) => {
      if (isOpen) {
        this.paymentShow = true;
      } else this.paymentShow = false;
    });
    this.subscriptions.push(paymentDialogSubscription);
  }

  ngOnInit(): void {
    const contactSubscription = this.surveyService.dialogCloseSubject.subscribe(
      (isClose: boolean) => {
        if (isClose) {
          this.isOpenMenu = false;
          this.contactForm.reset();
          this.paperRequestForm.reset();
          this.submitted = false;
          this.formSubmitted = false;
          this.isDisabled = false;
          this.selectedAnswer = "Select Option";
          this.dataList.findIndex((value) => {
            value.isSelected = false;
          });
        }
      }
    );
    this.subscriptions.push(contactSubscription);
    if(this.router.url === "/greena-index"){
      this.currentURL = "greena-index";
    } 
  }

  onKeyPress() {
    this.isDisabled = false;
  }

  onChange(e: any) {
    if (this.customAnswer) {
      this.isOtherOption = true;
      return;
    }
    this.isOtherOption = true;
    if (this.isOtherOption) {
      this.dataList.findIndex((value) => {
        value.isSelected = false;
      });
    }
    this.customAnswer = e.target.value;
    this.selectedAnswer = this.customAnswer;
    this.animationState = this.animationState === "out" ? "in" : "out";
  }

  // onSelectCode(value: any) {
  //   this.selectedCode = value.calling_code;
  // }

  submitForm() {
    this.submitted = true;
    this.commonErrorMessage = "";
    // const phone =
    //   "+" + "" + this.selectedCode + "" + this.contactForm.value.phone;
    if (this.customAnswer) {
      this.identityName = this.customAnswer;
    }
    if (!this.identityName && this.customAnswer) {
      this.errorMessage = "Please choose option or Add other identity.";
      this.isDisabled = true;
      return;
    } else if (!this.identityName && !this.customAnswer) {
      this.errorMessage = "Please choose option or Add other identity.";
      this.isDisabled = true;
      return;
    }
    if (this.contactForm.invalid) {
      return;
    } else {
      this.submitLoader = true;
      const data = {
        identity: this.identityName ? this.identityName : this.customAnswer,
        fullname: this.contactForm.value.name,
        email: this.contactForm.value.email,
        //phone: phone,
        message: this.contactForm.value.message,
      };
      this.saveContact(data);
    }
  }

  private saveContact(data:any){
    this.surveyService.saveContactData(data).subscribe(
      (res) => {
        if (res?.status != 400) {
          this.router.navigate(["/survey/submit/" + "successContact"]);
        } else if (!res.ok) {
          let errorObj = res.error;
          this.commonErrorMessage = Object.values(errorObj);
          this.submitLoader= false;
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Something Went Wrong!!",
        });
        this.submitLoader= false;
      }
    );
  }
  isOpen(name: string) {
    this.isOpenMenu = true;
    if (name === "option_menu") {
      this.animationState = this.animationState === "out" ? "in" : "out";
    }
  }

  onSelectOption(e: any, i: number, value: contactInfo) {
    this.data = [];
    this.isOtherOption = false;
    this.customAnswer = "";
    this.isClicked = true;
    this.isDisabled = false;
    this.selectedAnswer = value.name;
    this.dataList.findIndex((value) => {
      if (value.id === i) {
        value.isSelected = true;
        this.identityName = value.name;
      } else {
        value.isSelected = false;
      }
    });
    this.animationState = this.animationState === "out" ? "in" : "out";
  }
  get f() {
    return this.contactForm.controls;
  }

  get form() {
    return this.paperRequestForm.controls;
  }

  onPress(e: any) {
    return e.charCode === 8 || e.charCode === 0
      ? null
      : e.charCode >= 48 && e.charCode <= 57;
  }

  submitRequest() {
    this.formSubmitted = true;
    if (this.paperRequestForm.invalid) {
      return;
    } else {
      this.submitLoader = true;
      const requestData: any = {
        name: this.paperRequestForm.value.nameSurname,
        organization: this.paperRequestForm.value.organization,
        email: this.paperRequestForm.value.email,
        job: this.paperRequestForm.value.job,
      };

      let paperFormData = new FormData();
      Object.keys(requestData).forEach((key: string) => {
        let _value = requestData[key];
        if (_value) paperFormData.append(`${key}`, requestData[key]);
      });
      this.surveyService.savePaperRequestData(paperFormData).subscribe(
        (res: any) => {
          if (res?.success) {
            this.router.navigate(["survey/submit/" + "successPaperRequest"], {queryParams:{data:this.currentURL}});
            this.submitLoader = false;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Something Went Wrong!!",
            });
            this.submitLoader = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong!!",
          });
        }
      );
    }
  }

  //payment contact
  submitPaymentForm() {
    if (this.contactForm.invalid) {
      this.submitted = true;
      return;
    }
    this.submitLoader = true;
    const data = {
      identity: 'Hotel or restaurant',
      fullname: this.contactForm.value.name,
      email: this.contactForm.value.email,
      message: this.contactForm.value.message,
    };
    this.saveContact(data);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}
