<div class="glance-section">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="glance-title">
          <h3>Greena World at a Glance:</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <ng-container *ngFor="let glance of glanceList">
        <div class="col-md-4">
          <div class="glance-box">
            <h2>{{ glance.counts }}</h2>
            <p class="glance-name">{{ glance.name }}</p>
            <i [innerHTML]="glance.icon | safe"></i>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
