<div class="step-verification">
  <div class="step-head">
    <h4>
      <i [innerHTML]="icons['books_pricing'] | safe" class="d-inline-flex"></i>
      <div style="font-family: futura medium Bt;">
        <span class="d-inline" *ngIf="!router.url.includes('vendors/dashboard')">Step 2:</span>Get Your Personalized
        Improvement Guide.
      </div>
    </h4>
  </div>
  <div class="step-body">
    <div class="summary-verification">
      <div class="row justify-content-center flex-column-reverse flex-md-row">
        <div class="col-md-8 col-sm-12 col-12 position-relative">
          <p class="mb-4">If you're looking to make more improvements now or later, our sustainability experts will
            carefully review
            your answers and create a personalized sustainability roadmap for you. Think of it as getting top-notch
            consultancy insights but faster and at a much lower cost.</p>
          <p class="desc">Remember, it's entirely up to you when and how you choose to implement our feedback. The positive impact you've already made is significant, allowing you to benefit from it by sharing with your current and prospective guests.</p>
          <a (click)="showNotification()" class="btn btn-gray">Request a report</a>
        </div>
        <div class="col-md-4 col-sm-6 col-12">
          <div class="img-wrapper">
            <img src="../../../assets/img/epc-book.png" alt="epc-book" loading="lazy"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="isSuccess">
  <app-pop-up [popUpMessage]="popUpMessage" (isClick)="getClickEvent($event)" (closePopUp)="getCloseEvent($event)"></app-pop-up>
</ng-container>