import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlinkingTextComponent } from './blinking-text/blinking-text.component';
import { VendorDashboardComponent } from './vendor-dashboard/vendor-dashboard.component';
import { VendorQrcodeComponent } from './vendor-qrcode/vendor-qrcode.component';
import { GuestDashboardComponent } from './guest-dashboard/guest-dashboard.component';
import { CommunicateComponent } from './communicate/communicate.component';
import { BannerComponent } from './banner/banner.component';
import { PipeModule } from './pipe/pipe.module';
import { ProspectiveGuestsComponent } from './prospective-guests/prospective-guests.component';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { PersonalisedComponent } from './personalised/personalised.component';
import { PersonalizedGuideComponent } from './personalized-guide/personalized-guide.component';
import { PopUpComponent } from './pop-up/pop-up.component';
import { RouterModule } from '@angular/router';
import { GlanceComponent } from './glance/glance.component';

@NgModule({
  declarations: [
    BlinkingTextComponent,
    VendorDashboardComponent,
    VendorQrcodeComponent,
    GuestDashboardComponent,
    CommunicateComponent,
    BannerComponent,
    ProspectiveGuestsComponent,
    PersonalisedComponent,
    PersonalizedGuideComponent,
    PopUpComponent,
    GlanceComponent
  ],
  imports: [
    CommonModule,
    PipeModule,
    DialogModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbTooltipModule,
    ToastModule,
    FormsModule,
    RouterModule
  ],
  exports:[
    BlinkingTextComponent,
    BannerComponent,
    ProspectiveGuestsComponent,
    PersonalizedGuideComponent,
    PopUpComponent,
    GlanceComponent
  ],
  providers:[
    MessageService
  ]
})
export class SharedModule { }
