<!-- <app-header [image]="image"></app-header> -->
<p-toast></p-toast>
<app-banner></app-banner>
<app-glance></app-glance>
<!-- Enviroment Section Start -->
<div class="env-section">
  <div class="home-container">
    <div class="env-res-text">
      "Environmental and social responsibility matters to <span>86%</span> of
      guests. <br />
      <span>33%</span> actively check sustainability practices before booking."
    </div>
    <div class="hilton-wrap">
      <a 
        href="https://cr.hilton.com/wp-content/uploads/2019/05/Hilton-Corporate-Responsibility-2018.pdf" target="_blank">
        <img src="assets/img/svg/hilton.svg" alt="hilton" loading="lazy"/>
      </a>
      <p>Research with 73,000 participants.</p>
    </div>
    <div class="rapid-wrapper">
      <div class="left_wrap">
        <img src="assets/img/svg/global.svg" alt="global grow" loading="lazy"/>
        <h3>
          Tap into this rapidly <br />
          growing market
        </h3>
      </div>
      <div class="right_wrap">
        <button type="button" class="btn btn-light" (click)="onSignup()">
          Show your positive impact
          <i [innerHTML]="icon['arrowOutline'] | safe"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Enviroment Section End -->

<!-- How does it work Start -->
<section class="how-work">
  <div class="home-container" id="how-work">
    <h2 class="work-heading">How does it work?</h2>
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="work-box">
          <div class="work-box-heading">
            <i [innerHTML]="icon['file'] | safe"></i>
            <h6>Assess</h6>
          </div>
          <ul class="work-box-content">
            <li class="question-list">
              Discover your Greena Index
              <i class="question-icon mx-1" [ngbTooltip]="tipContent" tooltipClass="custom-tooltip" container="body"
                placement="bottom top-left top-right bottom-right bottom-left right"
                [innerHTML]="icon['questionIcon'] | safe" #t="ngbTooltip" (mouseenter)="onMouseEnter()"
                triggers="click:blur"></i>
              for free <a (click)="onSignup()">here.</a>
              <ng-template #tipContent>
                <div class="tooltip-wrap" (mouseleave)="onMouseLeave()">
                  <i [innerHTML]="icon['info'] | safe"></i>
                  <div class="tooltip-content">
                    <p class="home-tooltip-content">
                      The Greena Index indicates the level of positive impact a hotel or restaurant has on the environment, local community, and the well-being of its guests.
                    </p>
                    <a class="home-tooltip-link" href="/greena-index" target="_blank">Learn more<i class="ms-2"
                        [innerHTML]="icon['arrowAngleRight'] | safe"></i></a>
                  </div>
                </div>
              </ng-template>
            </li>
            <li>
              Compare your ranking against other hotels and restaurants in our
              network.
            </li>
            <li>Cut costs with personalised feedback and resources.</li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="work-box">
          <div class="work-box-heading">
            <i [innerHTML]="icon['verify'] | safe"></i>
            <h6>Verify</h6>
          </div>
          <ul class="work-box-content">
            <li>Effortlessly validate your sustainability accomplishments.</li>
            <li>
              Avoid greenwashing accusations with reliable third-party
              verification.
            </li>
            <li>Prepare for upcoming ESG regulatory requirements.</li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="work-box">
          <div class="work-box-heading">
            <i [innerHTML]="icon['communication'] | safe"></i>
            <h6>Communicate</h6>
          </div>
          <ul class="work-box-content">
            <li>
              Attract new customers by sharing your story across multiple
              channels.
            </li>
            <li>
              Enable your current customers to find out more about your positive
              impact.
            </li>
            <li>Boost your marketing and PR strategy.</li>
          </ul>
        </div>
      </div>
    </div>
    <button class="btn btn-white btn-start w-100 text-center" (click)="onSignup()">Start here <i
        [innerHTML]="icon['arrowOutline'] | safe"></i></button>
  </div>
</section>
<!-- How does it work End -->

<!-- Greena Path Start -->
<div class="greena-path-wrapper">
  <div class="home-container px-0" id="positive">
    <div class="path-inner-wrapper">
      <div class="top-wrapper" id="start-auto-scroll">
        <h4 class="text-primary">
          Don't Keep Your Positive Impact a Secret: Let Us Help You Share It
          with Prospective Guests
        </h4>
        <img src="../../assets/img/positive_impact.jpg" alt="positive image" loading="lazy"/>
      </div>
    </div>
    <app-prospective-guests [stopScrolling]="stopScrolling" [isPersonalized]="false"></app-prospective-guests>
    <div class="tell-the-story" id="tell-the-story">
      <h4 class="text-primary">
        <i [innerHTML]="icon['star'] | safe"></i>Tell your story
      </h4>
      <button class="btn btn-primary" (click)="onSignup()">
        Join us now! <i [innerHTML]="icon['arrowOutline'] | safe"></i>
      </button>
    </div>
  </div>
</div>
<!-- Greena Path End -->

<!-- Verified Start -->
<div class="verified-wrapper">
  <div class="home-container">
    <h2 class="title-text text-md-center">
      Hotels & restaurants with verified sustainability practices have:
    </h2>
    <div class="row stastic-row">
      <div class="col-sm-6" *ngFor="let item of sustainabilityList">
        <div class="stastic-wrap">
          <h3>{{ item.title }}</h3>
          <h6>
            {{ item.description
            }}<i [ngbTooltip]="tipContent" tooltipClass="custom-tooltip" container="body"
              placement="bottom-right bottom-left bottom top-left top-right left right" triggers="hover click:blur"
              [innerHTML]="item.icon | safe" [autoClose]="false"></i>
          </h6>
          <ng-template #tipContent>
            <div class="tooltip-wrap home-tooltip">
              <i [innerHTML]="icon['info'] | safe"></i>
              <div class="tooltip-content">
                <p><b>Source:</b> {{ item.tooltipContent }}</p>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-light w-100 justify-content-center" (click)="onSignup()">
      Improve your business
      <i [innerHTML]="icon['arrowLong'] | safe"></i>
    </button>
  </div>
</div>
<!-- Verified End -->
<!-- Organization Start -->
<ng-template *ngIf="isLoaded">
  <app-organization [networksList]="networksList" *ngIf="networksList.length > 3"></app-organization>
</ng-template>
<!-- Organization End -->
<!-- Book a call section Start -->
<section>
  <div class="home-container" id="calendy">
    <div class="calendy-part home-calendy">
      <div class="calendly-icon">
        <i [innerHTML]="icon['pcWithMan'] | safe"></i>
      </div>
      <h2 class="calendy-part-title">Interested in learning how to utilize your current sustainability achievements for
        your business growth?
        Book your free consultation now.</h2>
      <div class="calendy-row">
        <!-- <div id="calendly-widget"></div> -->
        <iframe src="https://meetings-eu1.hubspot.com/lukas-haitzmann/website-chain-signup-enquiry?embed=true" frameborder="0" width="100%" height="700px" title="book_call"></iframe>
      </div>
    </div>
  </div>
</section>
<!-- Book a call section End -->

<section class="action-row" *ngIf="isLoaded">
  <app-how-sections></app-how-sections>
</section>
<!-- Blog Section Start -->
<div class="home-blog-wrapper" *ngIf="blogData.length >= 3">
  <div class="home-container">
    <div class="home-blog-inner-wrap">
      <h2 class="mb-md-5 mb-4">
        Learn & get <br class="d-none d-md-block" />
        inspired <br class="d-block d-md-none" />
        by others
      </h2>
      <div class="row blog-card-row">
        <div class="col-6 col-md-4" *ngFor="let blog of blogList">
          <div class="blog-card" (click)="onShowDetails(blog.url_word)">
            <div class="card-image-wrap">
              <img [src]="blog?.thumbnail_image" [alt]="blog?.blog_type" loading="lazy"/>
              <div class="headphone-box" *ngIf="blog?.blog_type === 'podcasts'">
                <i [innerHTML]="icon['headphone'] | safe"> </i>
              </div>
            </div>
            <div class="card-desc-wrap">
              <label class="blog-type">{{ blog?.blog_type | titlecase }}</label>
              <h4>{{ blog?.title }}</h4>
              <a href="javascript:;" class="btn btn-sm btn-outline-light mt-auto"><span>{{
                  blog?.blog_type === "podcasts" ? "Listen" : "Read"
                  }}</span>
                <i [innerHTML]="
                    (blog?.blog_type === 'podcasts'
                      ? icon['mic']
                      : icon['arrowLong']
                    ) | safe
                  "></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="view-more">
        <button class="btn btn-outline-light" routerLink="/blog">
          View more <i [innerHTML]="icon['arrowOutline'] | safe"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Blog Section End -->

<!-- <app-footer></app-footer> -->