<!-- banner -->
<div id="cover-spin" *ngIf="isLoading"></div>
<section>
  <div class="home-container">
    <div class="row glx-banner justify-content-between m-0">
      <div class="col-lg-8 p-0">
        <h2 [innerHTML]="frontText"></h2>
      </div>
      <div class="col-lg-3 p-0 d-flex align-items-center">
        <div class="d-none d-lg-flex glx-banner-img">
          <img src="../../../assets/img/G-index-img.png" alt="greena" loading="lazy"/>
        </div>
        <div class="mt-4 d-lg-none">
          <img src="../../../assets/img/geena-index-img.png" alt="greena" loading="lazy"/>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- greena index -->
<section>
  <div class="home-container">
    <div class="row greena-pillars">
      <h3>The 3 pillars of Greena Index:</h3>
      <div class="col-12 col-md-6 col-xl-4 mb-3">
        <div class="greena-pillars-card">
          <div class="d-flex align-items-center justify-content-between">
            <h3 [class]="this.language == 'en' ? 'pillars_title' : ''">
              Eco
            </h3>
            <img
              class="greena-icon-img"
              src="../../../assets/img/outlined_leave_icon.svg"
              alt="outlined_leave"
              loading="lazy"
            />
          </div>
          <div class="greena-pillars-card-content">
            <div class="ul-heading">
              <p class="eco-desc">Level of positive impact on environment</p>  
            </div>
            <ul>
              <li>
                Combating climate change (e.g. reducing CO2 emissions and plastic waste, promoting recycling initiatives)
              </li>
              <li>
                Minimising impact on biodiversity
              </li>
              <li>
                Mitigating impact on natural resources (e.g. Reducing water usage and air pollution)
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 mb-3">
        <div class="greena-pillars-card">
          <div class="d-flex align-items-center justify-content-between">
            <h3 [class]="this.language == 'en' ? 'pillars_title' : ''">
              Social
            </h3>
            <img
              class="greena-icon-img"
              src="../../../assets/img/outlined_group_icon.svg"
              alt="outlined_group"
              loading="lazy"
            />
          </div>
          <div class="greena-pillars-card-content">
            <div class="ul-heading">
              <p class="social-desc">Level of positive impact on local communities & staff</p>
            </div>
            <ul>
              <li>
                Positive impact on local communities (e.g. supporting local projects)
              </li>
              <li>
                Providing and promoting gender equality and staff development opportunities
              </li>
              <li>Fair pay and good working standards for employees</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 mb-3">
        <div class="greena-pillars-card">
          <div class="d-flex align-items-center justify-content-between">
            <h3 [class]="this.language == 'en' ? 'pillars_title' : ''">
              Wellbeing
            </h3>
            <img
              class="greena-icon-img"
              src="../../../assets/img/outlined_men_heart_icon.svg"
              alt="outlined_heart"
              loading="lazy"
            />
          </div>
          <div class="greena-pillars-card-content">
            <div class="ul-heading">
              <p class="wellbing-desc">Level of positive impact on guests' health</p>
            </div>
            <ul>
              <li>
                Use of non-toxic cleaning products and cosmetics
              </li>
              <li>
                Providing organic food
              </li>
              <li>
                Providing green recreational spaces
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- get greena index -->
    <div class="greena-index-wrap">
      <h4 class="text-primary">
        <i [innerHTML]="icons['star'] | safe"></i>Get your Greena Index
      </h4>
      <a
        class="btn btn-primary btn-calculate"
        target="_blank"
        routerLink="/auth/signup"
      >
        Calculate now<i [innerHTML]="icons['arrowOutline'] | safe"></i>
      </a>
    </div>
  </div>
</section>

<!-- review -->
<section class="greena-review-sec" *ngIf="endoresArray.length >= 4 && isLoaded">
  <div class="home-container">
    <h3>
      The Greena Index was reviewed and endorsed by dozens of leading
      environmental scientists including:
    </h3>
    <div class="row review-row">
      <div class="review-col review-card">
        <owl-carousel-o [options]="greenaindex">
          <ng-template carouselSlide *ngFor="let data of endoresArray">
            <div class="greena-review-card">
              <div class="card-image">
                <img
                  [src]="data?.photo"
                  class="w-100 h-100 object-fit-contain"
                  alt="img"
                  loading="lazy"
                />
              </div>
              <div class="cards-contain">
                <h4>{{data?.name}} {{data?.surname}}</h4>
                <p>{{data?.university}}</p>
                <div class="social-icon align-items-center">
                  <a [href]="data?.linkedin" target="_blank"><i [innerHTML]="icons['linkedin'] | safe"></i></a>
                  <a [href]="data?.general_link" target="_blank"><i [innerHTML]="icons['link'] | safe"></i></a>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>

<!-- greena action sec -->
<section class="action-row" *ngIf="isLoaded">
  <app-how-sections></app-how-sections>
</section>

<!-- greena-levels -->

<section class="main-greena-levels">
  <div class="home-container">
    <div class="col-12 mx-auto">
      <div class="greena-levels-text">
        <img
          src="assets/img/greena_level_logo.svg"
          alt="Greena levels"
          class="greena_level_logo"
          loading="lazy"
        />
        <h2>The Greena Levels:</h2>
        <div class="greena-content">
          <p>
            Becoming sustainable is not about the final destination, but the journey. We take a step-by-step approach with hotels and restaurants, providing them with practical feedback and resources so they can continue being a force for good.
          </p>
          <p>
            We believe that the green transition should be simple, transparent, and, above all, fun.
          </p>
          <p>
            That's why we take pride in using technology to help the tourism sector have a lasting positive impact on our planet and its people.
          </p>
          <p>
            Any Greena Index score between 0 and 100 is considered good, with the score difference reflecting different levels of positive impact.
          </p>
          <p>
            Here's what the different Greena Index scores mean:
          </p>
        </div>
      </div>
      <ul class="greena-levels-steps">
        <!-- greena pleage start-->
        <li class="levels-steps-text" *ngFor="let data of greenaScore">
          <div class="levels-steps-image">
            <i [innerHTML]="data.icon | safe"></i>
          </div>
          <p>{{data?.content}}</p>
        </li>
        <!-- greena pleage end-->
        <li>
          <div class="levels-steps-text calculate-step-text">
            <p class="calculate-text" (click)="gotoSignpUp()">
              Calculate your Greena Index
              <i [innerHTML]="icons['crossArrow'] | safe"></i>
            </p>
          </div>
        </li> 
      </ul>
    </div>
  </div>
</section>

<section>
  <div class="home-container">
    <div class="scientific-wrap container" id="scientific-paper">
      <div
        class="scientific-wrap-header d-md-flex align-items-center justify-content-between"
      >
        <div class="head-left-wrap d-md-flex">
          <div class="head-icon">
            <img src="../../../assets/img/notes_icon.png" alt="note" loading="lazy"/>
          </div>
          <div class="glx-common-title">
            <h3>Scientific paper:</h3>
            <p>The science behind the Greena Index: Hospitality 4.0</p>
          </div>
        </div>
        <div class="head-right-wrap">
          <a (click)="paperRequest()" class="btn btn-primary download-btn">
            Download <i [innerHTML]="icons['download_icon'] | safe"></i>
          </a>
        </div>
      </div>
      <div class="abstract-section">
        <h3>Abstract</h3>
        <p>
          Over the past few decades, human life has made a significant impact on
          our planet, triggering a new geological era, the Anthropocene
          <sup (click)="activeReferenceTab()">[1]</sup>.The Anthropocene is
          depicted by the human dominance of the earth
          <sup (click)="activeReferenceTab()">[2]</sup>, triggering a multitude
          of interrelated effects on the climate, including global warming and a
          major loss in biodiversity. These impacts also create substantial
          negative and interdependent economic and social impacts on
          societies.
          The travel and tourism industry accounted for around 10.2% of the
          global GDP in 2018. Around 173 million people were employed across
          over 700,000 hotels and resorts worldwide
          <sup (click)="activeReferenceTab()">[3]</sup> in the Hospitality
          industry before the COVID-19 pandemic.
        </p>
        <p class="">
          With international tourism accounting for around 5% to 8% of global
          GHG emissions <sup (click)="activeReferenceTab()">[4]</sup> , a key
          opportunity to promote sustainability focused behaviours has been
          identified by leading researchers as smart, digital solutions, branded
          as ‘Hospitality 4.0’. Hospitality 4.0 encompasses technologies that
          boost the sector and supports and boosts its economic recovery.
          <span [ngClass]="isShowMore ? 'd-none' : 'd-inline'">
            <span *ngIf="!isShowMore">...</span>
            <span class="show-less-btn" (click)="showText()">
              {{ isShowMore ? "Show less" : "See more" }}
            </span>
          </span>
        </p>
        <p
          class=""
          [ngStyle]="{ visibility: isShowMore ? 'visible' : 'hidden' }"
          *ngIf="isShowMore"
        >
          The Greena Index (GIx) provides a transparent rating for hotels and
          restaurants in terms of their sustainability characteristics. The GIx
          is broadly based on the 17 United Nations Sustainable Development
          Goals (SDGs) <sup (click)="activeReferenceTab()">[7]</sup>. The
          questions included in the GIx can be categorised along three impact
          dimensions which are (1) ‘Eco-impact’ which includes two
          sub-categories (GHG-emissions/climate change and
          pollution/biodiversity), (2) health and (3) social
          sustainability/equality. The scoring reflects the level of impact of a
          variable in its respective dimension, with the more significant
          factors receiving a higher scoring. The GIx of a vendor includes the
          total scoring, benchmarking to similar businesses, and a breakdown of
          scoring for each of the three dimensions.
        </p>
        <p class=""
          [ngStyle]="{ visibility: isShowMore ? 'visible' : 'hidden' }"
          *ngIf="isShowMore"
        >
          The GIx is not intended to be a static tool and will be subject to
          continuous adaptation. Changes will be based on feedback mechanisms,
          and statistical analysis of the most impactful criteria in terms of
          preserving our planet and social standards.
        </p>
        <p
          [ngStyle]="{ visibility: isShowMore ? 'visible' : 'hidden' }"
          *ngIf="isShowMore"
        >
          For a more in-depth explanation of the scientific approach behind the
          Greena Index (GIx) you can register here to receive our peer reviewed
          scientific article.
          <span [ngClass]="isShowMore ? 'd-inline' : 'd-none'">
  
            <span class="show-less-btn" (click)="showText()">
              {{ isShowMore ? "Show less" : "See more" }}
            </span>
          </span>
        </p>
        
      </div>

      <div class="reference-list" id="reference-list">
        <h5>Reference</h5>
        <ul>
          <li>
            Ruddiman, W.F. The Anthropocene. Annu. Rev. Earth Planet. Sci. 2013,
            41, 45–68,
            <span>doi:10.1146/annurev-earth-050212-123944.</span>
          </li>
          <li>
            Malhi, Y. The Concept of the Anthropocene. Annu. Rev. Environ.
            Resour. 2017, 42, 77–104,
            <span>doi:10.1146/annurev-environ-102016-060854.</span>
          </li>
          <li>
            Finances Online. 109 Hospitality Statistics You Must Know: 2021/2022
            Data Analysis & Market Share.
          </li>
          <li>
            <span
              >Lenzen, M.; Sun, Y.-Y.; Faturay, F.; Ting, Y.-P.; Geschke,
              A.</span
            >; Malik, A. The carbon footprint of global tourism. Nat. Clim.
            Chang. 2018, 8, 522–528, doi:10.1038/s41558-018-0141-x.
          </li>
          <li>
            <span>Frank,A.G.;Mendes,G.H.S.;Ayala,N.F.; Ghezzi,A.</span>
            Servitization and Industry 4.0 convergence in the digital
            transformation of product firms: A business model innovation
            perspective. Technol. 6.
          </li>
          <li>
            Forecast. Soc.
            <span>
              Change 2019, 141, 341–351,
              doi:10.1016/j.techfore.2019.01.014.</span
            >
          </li>
          <li>
            <span>Ben Youssef,A.;Zeqiri</span>, A. Hospitality Industry 4.0
            and Climate Change. Circ. Econ. Sustain. 2022,
            doi:10.1007/s43615-021-00141-x.
          </li>
          <li>
            United Nations: Department of Economic and Social Affairs United
            Nationas Sustainable Development Goals Available online:
            <span><a href="">https://sdgs.un.org/goals.</a></span>
          </li>
          <li>
            <span
              >Gielen ,D.;Boshell, F.; Saygin,D.; Bazilian, M.D.; Wagner,N.;Gorini, R.</span
            >
            The role of renewable energy in the global energy transformation.
            Energy Strateg. Rev. 2019, 24, 38–50, doi:10.1016/j.esr.2019.01.006.
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<p-dialog
  header="Please request the scientific paper “The science behind the Greena-Index: Hospitality 4.0” by filling in the form below."
  [(visible)]="displayPaper"
  [modal]="true"
  [style]="{ width: '713px' }"
  [draggable]="false"
  class="contact_popup paper_request_form"
  (onHide)="close()"
  [closeOnEscape]="false"
>
  <app-contact-us></app-contact-us>
</p-dialog>

<!-- <app-footer></app-footer> -->
