import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivePlan, BillHistory, DiscountCouponSuccess, MethodList, MethodListResponseResult, PaymentFAQ } from '../interface/payment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private url: string = environment.baseURL + "pricing/";
  constructor(private _http: HttpClient, private route:Router ) { }

  public addPaymentMethod(data:any,paymentType:string,uid:string){
    return this._http.post(`${this.url}payment_method/${paymentType}/${uid}`,data);
  }
  public getSubscriptionDetails(uid:string){
    return this._http.get(`${this.url}payment_method/${uid}`);
  }

  public takeSubscription(data:any,uid:string){
    return this._http.post(`${this.url}subscription/${uid}`,data);
  }

  public methodList(uid:string):Observable<MethodList>{
    return this._http.get<MethodList>(`${this.url}payment_method/${uid}`);
  }

  public deleteCard(id:number,uid:string){
    return this._http.delete(`${this.url}payment_method/${id}/${uid}`);
  }

  public setDefaultCard(id:number,uid:string):Observable<MethodListResponseResult>{
    return this._http.put<MethodListResponseResult>(`${this.url}payment_method/${id}/${uid}`,'');
  }
  
  public getActivePlan(uid:string):Observable<ActivePlan>{
    return this._http.get<ActivePlan>(`${this.url}subscription/${uid}`);
  }

  public paymentHistory(uid:string):Observable<BillHistory>{
    return this._http.get<BillHistory>(`${this.url}subscription_history/${uid}`);
  }

  public getPaymentFAQ(): Observable<PaymentFAQ[]>{
    return this._http.get<PaymentFAQ[]>(`${this.url}subscription-faqs`);
  }

  public discountCoupon(code:any): Observable<DiscountCouponSuccess>{
    return this._http.post<DiscountCouponSuccess>(`${this.url}validate-coupon`,code);
  }

  public checkActivePlan(venderId:any) {
    this.getActivePlan(venderId).subscribe({
      next: (res: ActivePlan) => {
        if (res.stripe_subscription_status && res.stripe_subscription_status !== 'incomplete') {
          if(typeof window !== "undefined"){
            window.open(`/payment/payment-history/${venderId}`,"_blank");
          }
        } else {
          if(typeof window !== "undefined"){
            window.open(`/payment/subscription/${venderId}`,"_blank");
          }
        }
      },
      error: (err: any) => {
        console.log('err :>> ', err);
      }
    });
  }

  public createManualSubscription(data:any,venderId:string){
    return this._http.post<any>(`${this.url}manual_invoice_subscription/${venderId}`,data);
  }

  public deleteSubscription(subscriptionId:any,venderId:string){
    return this._http.post<any>(`${this.url}delete_subscription/${venderId}`,subscriptionId);
  }
}
