import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { MessageService } from "primeng/api";
import {
  Customer_id,
  DisplayTopData,
  EnvironmentalData,
  GreenaIndexDetails,
  GuestDetails,
  LanguageTraslate,
  ResDisputeData,
} from "src/app/_interfaces/common.model";
import { SlideInOutAnimation } from "src/app/contact-us/animation";
import { CommonService } from "src/app/services/common.service";
import { VendorsService } from "src/app/services/vendors.service";
import { icons } from "src/assets/img/svg/svg";

@Component({
  selector: "app-guest-dashboard",
  templateUrl: "./guest-dashboard.component.html",
  styleUrls: ["./guest-dashboard.component.scss"],
  animations: [SlideInOutAnimation],
})
export class GuestDashboardComponent implements OnInit {
  @ViewChild("languageMenu") languageMenu!: ElementRef;
  public isOpenMenu: boolean = false;
  public showOption: boolean = false;
  public icon = icons;
  public displayContact: boolean = false;
  public desputeForm!: FormGroup;
  public isLoading: boolean = false;
  private vendorsId!: string;
  public hotelDetails!: GuestDetails;
  private UUID: any;

  public viewMoreCategorie: string = "View more";
  public topCategorie: DisplayTopData[] = [];
  public categorieMoreDetails: DisplayTopData[] = [];
  public environmentalData: Customer_id[] = [];
  public socialData: Customer_id[] = [];
  public healthData: Customer_id[] = [];
  private isEnvironmental: boolean = false;
  private isSocial: boolean = false;
  private isHealth: boolean = false;
  public submitLoader: boolean = false;
  public showError: boolean = false;
  public currentIndex: number = 0;
  public currentJindex: number = 0;
  public errorMsg: string = "Claim is required.";
  // public isSuccess: boolean = false;
  public isAcceptPolicy:boolean = false;
  public isChooseFile: boolean = false;

  public selectedAnswer: string = "Select claim";
  private questionTooltip:any;
  private optionTooltip:any;
  private isQuestionTooltip:boolean = false;
  private isOptionTooltip:boolean = false;
  public optionIndex!:any;
  public questionIcon!:any;
  @HostListener('document:click', ['$event.target'])
  onDocumentClick(targetElement: HTMLElement): void {
    //For question tooltip handle start
    if(this.questionTooltip){
      const questionIcon = this.questionIcon.contains(targetElement);
      if ((this.questionTooltip && this.questionTooltip.isOpen() && !questionIcon) || !this.isQuestionTooltip) {
        this.questionTooltip && this.questionTooltip.close();
      }
      if (this.questionTooltip && this.questionTooltip.isOpen() && !questionIcon) {
        this.onQuestionIconClick(this.questionIcon);
        this.isQuestionTooltip = false;
      }
    }
    //For question tooltip handle end

    //For option tooltip handle start
    if(this.optionTooltip){
      const optionIcon = this.optionIndex.contains(targetElement);
      if ((this.optionTooltip && this.optionTooltip.isOpen() && !optionIcon) || !this.isOptionTooltip) {
        this.optionTooltip.close();
      }
      if (this.optionTooltip && this.optionTooltip.isOpen() && !optionIcon) {
        this.onOptionIconClick(this.optionIndex);
        this.isOptionTooltip = false;
      }
    }
    //For option tooltip handle end
  }
  public language: LanguageTraslate = {
    image: `assets/img/svg/english.svg`,
    lable: "English",
    language: "en",
  };

  // public popUpMessage = {
  //   message: 'Thank you for your report! Our verification team will investigate further.',
  //   closeIcon: this.icon['close_icon'],
  //   borderColor: '#48a43f',
  //   icon: this.icon['checkBox']
  // };

  // @HostListener("document:click", ["$event.target"])
  // @HostListener("document:scroll", ["$event.target"])
  // public onPageClick(targetElement: HTMLElement) {
  //   const clickedInside =
  //     this.languageMenu.nativeElement.contains(targetElement);
  //   if (!clickedInside) this.showOption = false;
  // }

  public uploadedFiles: File[] = [];
  public venderDetails: GreenaIndexDetails[] = [
    {
      title: "Eco",
      value: 68,
      content: "environmental",
      tooltipContent: `The 'eco impact' refers to the influence that this property has on the natural environment. It encompasses actions like reducing CO2 emissions and plastic waste, utilising renewable energy, promoting local biodiversity, and mitigating the impact on natural resources by minimising water and air pollution.`,
      category: "environmental",
      expanded: false,
    },
    {
      title: "Social",
      value: 92,
      content: "social",
      tooltipContent: `The 'social impact' refers to the property's contribution to the local community and its own staff. It includes supporting local projects, providing and promoting gender equality, offering staff development opportunities, and ensuring fair pay and good working standards for employees.`,
      category: "social",
      expanded: false,
    },
    {
      title: "Wellbeing",
      value: 88,
      content: "health",
      tooltipContent: `The term 'well-being' refers to the property's efforts to enhance the health and satisfaction of their guests. This includes ensuring cleanliness, using non-toxic cleaning products and cosmetics, offering organic and locally sourced food, providing green recreational spaces, and striving for high customer satisfaction.`,
      category: "health",
      expanded: false,
    },
  ];

  public accounts = [
    {
      name: "Adam",
      email: "adam@email.com",
      age: 12,
      country: "United States",
      child: { state: "Active" },
    },
    {
      name: "Homer",
      email: "homer@email.com",
      age: 47,
      country: "",
      child: { state: "Active" },
    },
    {
      name: "Samantha",
      email: "samantha@email.com",
      age: 30,
      country: "United States",
      child: { state: "Active" },
    },
    {
      name: "Amalie",
      email: "amalie@email.com",
      age: 12,
      country: "Argentina",
      child: { state: "Active" },
    },
    {
      name: "Estefanía",
      email: "estefania@email.com",
      age: 21,
      country: "Argentina",
      child: { state: "Active" },
    },
    {
      name: "Adrian",
      email: "adrian@email.com",
      age: 21,
      country: "Ecuador",
      child: { state: "Active" },
    },
    {
      name: "Wladimir",
      email: "wladimir@email.com",
      age: 30,
      country: "Ecuador",
      child: { state: "Inactive" },
    },
    {
      name: "Natasha",
      email: "natasha@email.com",
      age: 54,
      country: "Ecuador",
      child: { state: "Inactive" },
    },
    {
      name: "Nicole",
      email: "nicole@email.com",
      age: 43,
      country: "Colombia",
      child: { state: "Inactive" },
    },
    {
      name: "Michael",
      email: "michael@email.com",
      age: 15,
      country: "Colombia",
      child: { state: "Inactive" },
    },
    {
      name: "Nicolás",
      email: "nicole@email.com",
      age: 43,
      country: "Colombia",
      child: { state: "Inactive" },
    },
  ];

  public translate_language: LanguageTraslate[] = [
    {
      image: `assets/img/svg/english.svg`,
      lable: "English",
      language: "en",
    },
    {
      image: `assets/img/svg/france.svg`,
      lable: "François",
      language: "fr",
    },
  ];

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private vendorsService: VendorsService,
    private route: ActivatedRoute,
    private tooltipConfig: NgbTooltip,
    private commonService: CommonService,
    private router: Router
  ) {
    this.desputeForm = this.fb.group({
      dispute_details: [null, [Validators.required]],
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      image: [null, [Validators.required]],
    });

    this.route.params.subscribe((param: Params) => {
      this.vendorsId = param.id;
    });
  }

  public onGoto(data: string) {
    this.commonService.onGoto(data);
  }

  ngOnInit(): void {
    this.getEnvironmentData();
    this.getGuestDetails();
  }

  onPopupClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  onQuestionIconClick(i:any) {
    this.questionIcon = i;
    this.isQuestionTooltip = true;
  }

  onOptionIconClick(j: any) {
    this.optionIndex = j;
    this.isOptionTooltip = true;
  }

  public closeMenu() {
    this.isOpenMenu = false;
    document.getElementsByTagName("HTML")[0].classList.remove("hideScroll");
  }

  public showMenu() {
    this.showOption = !this.showOption;
  }

  public open() {
    this.desputeForm.reset();
    this.selectedAnswer = "Select claim";
    this.showError = false;
    this.currentIndex = 0;
    this.currentJindex = 0;
    this.isChooseFile = false;
    this.displayContact = true;
    this.isOpenMenu = false;
  }

  public onDialogClose() {
    this.changedData(false);
  }

  get fControl() {
    return this.desputeForm.controls;
  }
  public onDesputeSubmit() {
    if (this.desputeForm.invalid) {
      this.desputeForm.markAllAsTouched();
      this.showError =
        this.selectedAnswer === "Select claim"
          ? !this.showError
          : this.showError;
      this.isChooseFile = true;
      return;
    }
    // setTimeout(() => {
    // }, 2000);
    this.displayContact = false;
    this.submitLoader = true;
    const formData = new FormData();
    formData.append("name_of_vendor", this.UUID);
    formData.append("dispute_customer_id", this.selectedAnswer);
    formData.append("dispute_details", this.fControl["dispute_details"].value);
    formData.append("name", this.fControl["name"].value);
    formData.append("email", this.fControl["email"].value);
    this.uploadedFiles.forEach((file: File) => {
      formData.append("image", file);
    });
    this.vendorsService.sendDisputeData(formData).subscribe({
      next: (res: ResDisputeData) => {
        this.desputeForm.reset();
        this.uploadedFiles = [];
        // this.isSuccess = true;
        // setTimeout(() => {
        //   this.isSuccess = false;
        // }, 2000);
        this.submitLoader = false;
        this.selectedAnswer = "Select claim";
        this.isChooseFile = false;
        this.router.navigate(['/survey/dispute/thank-you'],{queryParams:{guest:this.vendorsId}});
      },
      error: (err: any) => {
        this.submitLoader = false;
        this.messageService.add({
          severity: "error",
          detail: err.error.detail ? err.error.detail : 'Somthing went wrong!',
        });
        this.desputeForm.reset();
        this.isChooseFile = false;
        this.uploadedFiles = [];
      },
    });
  }

  public onSelectLanguage(data: LanguageTraslate) {
    this.language = data;
  }

  /**
   * The `uploadFile` function checks the size and format of the selected files, and adds them to the
   * `uploadedFiles` array if they meet the criteria.
   * @param {File[]} files - An array of File objects that represent the files to be uploaded.
   */
  uploadFile(files: File[]): void {
    let fileSize = 1024 * 1024 * 3;
    if (this.uploadedFiles.length < 5 && files.length <= 5) {
      Object.values(files).forEach((file) => {
        if (this.uploadedFiles.length < 5) {
          if (file?.size <= fileSize) {
            this.uploadedFiles.push(file);
          } else {
            this.messageService.add({
              severity: "error",
              detail: "Selected file size exceeds 3MB limit.",
            });
          }
        } else {
          this.messageService.add({
            severity: "error",
            detail: "Maximum 5 files uploaded.",
          });
        }
      });
    } else {
      this.messageService.add({
        severity: "error",
        detail: "Maximum 5 files uploaded.",
      });
    }
    this.isChooseFile = false;
  }

  public onRemoveImage(i: number) {
    this.uploadedFiles.splice(i, 1);
    this.isChooseFile = !this.uploadedFiles.length ? true : false;
    if (!this.uploadedFiles.length) {
      this.desputeForm.get("image")?.setValue("");
    }
  }

  private getGuestDetails() {
    this.isLoading = true;
    this.vendorsService.guestDetails(this.vendorsId).subscribe({
      next: (res: GuestDetails) => {
        this.hotelDetails = res;
        this.UUID = res.uuid;
        this.checkPolicy(res.id)
      },
      error: (err: any) => {
        this.isLoading = false;
        this.messageService.add({
          severity: "error",
          detail: "Something went wrong.",
        });
      },
    });
  }

  private getEnvironmentData() {
    this.vendorsService.environmentData(this.vendorsId).subscribe({
      next: (res: EnvironmentalData[]) => {
        this.handleCategories(res);
        this.isLoading = false;
      },
      error: (err: any) => {
        if(err.status === 404){
          this.messageService.add({
            severity: "error",
            detail: "Dashboard not found!",
          });
          setTimeout(() => {
            this.router.navigate(['/']);
          }, 1000);
        }
        this.isLoading = false;
      },
    });
  }

  private handleCategories(categorie: EnvironmentalData[]) {
    this.topCategorie = categorie[0].display_on_top;
    this.categorieMoreDetails = categorie[1].display_on_dashboard;

    this.topCategorie.filter((data: DisplayTopData) => {
      this.bindData(data);
    });
    this.categorieMoreDetails.filter((data: DisplayTopData) => {
      this.bindData(data);
    });
    this.changedData();
  }

  private bindData(dataSet: DisplayTopData) {
    switch (dataSet.question_category) {
      case "environmental":
        this.environmentalData.push(dataSet.customer_id);
        break;
      case "social":
        this.socialData.push(dataSet.customer_id);
        break;
      case "health":
        this.healthData.push(dataSet.customer_id);
        break;
      default:
        console.log("Something went wrong.");
        break;
    }
  }

  public viewMore(category: string) {
    const detail = this.venderDetails.find((item) => item.content === category);
    if (detail) {
      detail.expanded = !detail.expanded;
    }

    switch (category) {
      case "environmental":
        this.isEnvironmental = !this.isEnvironmental;
        break;
      case "social":
        this.isSocial = !this.isSocial;
        break;
      case "health":
        this.isHealth = !this.isHealth;
        break;
      default:
        console.log("Error occured");
        break;
    }
    this.changedData(false);
  }

  public changedData(dispat?: boolean) {
    this.venderDetails = this.venderDetails.map((data: any) => {
      return {
        ...data,
        options: this.getContentByCategory(data.content, dispat),
      };
    });
  }

  trackFn(index: number) {
    return index;
  }

  public getContentByCategory(
    category: string,
    isDispatch?: boolean
  ): Customer_id[] {
    switch (category) {
      case "environmental":
        return isDispatch || this.isEnvironmental
          ? this.environmentalData
          : this.environmentalData.slice(0, 3);
      case "social":
        return isDispatch || this.isSocial
          ? this.socialData
          : this.socialData.slice(0, 3);
      case "health":
        return isDispatch || this.isHealth
          ? this.healthData
          : this.healthData.slice(0, 3);
      default:
        return [];
    }
  }

  public onMouseEnter(tooltip: NgbTooltip,questionIcon:any) {
    this.tooltipConfig.triggers = "manual";
    this.questionIcon = questionIcon;
    if (this.questionTooltip && this.questionTooltip.isOpen()) {
      this.questionTooltip.close();
    }
    this.questionTooltip = tooltip;
    tooltip.open();
    if (this.optionTooltip && this.optionTooltip.isOpen()) {
      this.optionTooltip.close();
    }
  }

  // public onMouseLeave(tooltip: NgbTooltip) {
  //   tooltip.close();
  // }

  public onClickClose(tooltip: NgbTooltip) {
    tooltip.close();
  }

  public onClickClose2(tooltip2: NgbTooltip) {
    tooltip2.close();
  }

  public onMouseEnter2(tooltip2: NgbTooltip,optionIcon:any) {
    this.tooltipConfig.triggers = "manual";
    this.optionIndex = optionIcon;
    if (this.optionTooltip && this.optionTooltip.isOpen()) {
      this.optionTooltip.close();
    }
    this.optionTooltip = tooltip2;
    tooltip2.open();
    if (this.questionTooltip && this.questionTooltip.isOpen()) {
      this.questionTooltip.close();
    }
  }

  // public onMouseLeave2(tooltip2: NgbTooltip) {
  //   tooltip2.close();
  // }

  public onClick() {
    this.isOpenMenu = !this.isOpenMenu;
    // this.isOpenMenu  ? this.changedData(true) :  this.changedData(false);
    this.changedData(true);
  }

  public onSelectClaim(j: number, i: number, data: string) {
    this.currentIndex = i + 1;
    this.currentJindex = j + 1;
    this.selectedAnswer = data;
    this.onClick();
    this.showError = false;
  }

  public gotoPolicy(url:string){
    if(typeof window !== "undefined"){
      window.open(url,'_blank');
    }
  }

  private checkPolicy(id:number){
    if (typeof localStorage !== "undefined") {
      const temp:any = localStorage.getItem(this.vendorsId)
      const isPolicy = JSON.parse(temp);
      if(isPolicy && (isPolicy.id === id && isPolicy.accepted)){
        this.isAcceptPolicy = !this.isAcceptPolicy;
      }
    }
  }
  public onAcceptPolicy(action: boolean) {
    if (action) {
      this.isAcceptPolicy = !this.isAcceptPolicy;
      if (typeof sessionStorage !== "undefined") {
        const data = {
          id: this.hotelDetails.id,
          accepted: this.isAcceptPolicy
        }
        localStorage.setItem(this.vendorsId, JSON.stringify(data));
      }
    } else {
      if (typeof window !== "undefined") {
        window.location.href = "https://www.google.com";
      }
    }
  }
}
