// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripePublicKey: "pk_live_51NjM3TDt9lvEtvsk4Jtf5FSsXGX8LsuXR6e7lRzJ4sl6BNGTN631EJJYt2l9mlBDXuduCmGKkIDtuoV3wPQW0QSj00er1mWeT1",
  // https://greena-world.herokuapp.com/
  baseURL: "https://admin.greena.world/",
  // baseURL: "http://18.169.41.36:8001/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
