<p-toast></p-toast>
<ng-container *ngIf="paymentShow">
  <div class="contact_signup_form">
    <form action="" [formGroup]="contactForm">
      <div class="form-group">
        <label>1. Name and surname</label>
        <input type="text" placeholder="Linda Devenport" class="form-control" formControlName="name" />
        <div *ngIf="submitted && f.name.errors" class="invalid-controls">
          <span *ngIf="f.name.errors.required">Name and surname are required.</span>
        </div>
      </div>
      <div class="form-group">
        <label>2. Email</label>
        <input type="email" placeholder="linda@gmail.com" class="form-control" formControlName="email" />
        <div *ngIf="submitted && f.email.errors" class="invalid-controls">
          <span *ngIf="f.email.errors.required">Email is required.</span>
          <span *ngIf="f.email.errors.email">Please Enter a valid email.</span>
        </div>
      </div>
      <div class="form-group">
        <label>3. Your message. How can we assist you?</label>
        <textarea name="" class="form-control custam_textarea contact_form" id="" rows="4" placeholder="Write details here..."
          formControlName="message"></textarea>
      </div>

      <div style="color: red">{{ commonErrorMessage }}</div>
      <div class="form-group">
        <a class="comman_anchor" (click)="submitPaymentForm()">Submit 
          <span
          *ngIf="submitLoader"
          class="mx-2 spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span></a>
      </div>
    </form>
  </div>
</ng-container>

<ng-container *ngIf="!paperShow && !paymentShow">
  <div class="contact_signup_form">
    <form action="" [formGroup]="contactForm">
      <div class="form-group">
        <label>1. What identity best describes you?</label>
        <div class="dropdown option_dropdown">
          <div class="option_selector">
            <span *ngIf="!isOtherOption" [ngStyle]="isClicked ? { color: '#241F1F' } : { color: '#808080' }">{{
              selectedAnswer }}</span>
            <button class="dropbutton" type="button" id="dropdownMenuButton1" (click)="isOpen('option_menu')">
              <img src="assets/img/country_drop_arrow.svg" width="25" alt="Dropdown arrow" loading="lazy"/>
            </button>
            <ng-container *ngIf="isOtherOption">
              <input type="text" [placeholder]="isOtherOption ? 'Type your answer here' : ''"
                class="custam_answer_input form-control" [(ngModel)]="customAnswer" (change)="onChange($event)"
                (keypress)="onKeyPress()" [value]="customAnswer" [ngModelOptions]="{ standalone: true }" />
            </ng-container>
          </div>
          <ul class="dropdown-menu option_menu" [ngClass]="isOpenMenu ? 'p-relative' : ''"
            aria-labelledby="dropdownMenuButton1" [@slideInOut]="animationState">
            <li [ngClass]="
                data.isSelected
                  ? ' option_answer_wrap selected'
                  : 'option_answer_wrap'
              " *ngFor="let data of dataList; let i = index" (click)="onSelectOption($event, i, data)">
              <span>{{ data.name }}</span>
            </li>
            <li class="option_answer_wrap" [ngClass]="isOtherOption ? 'selected' : ''">
              <span (click)="onChange($event)">{{ customAnswer ? customAnswer : "Something else (add answer)" }}
              </span>
            </li>
          </ul>
        </div>
        <div *ngIf="isDisabled" class="invalid-controls">
          <span> {{ errorMessage }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>2. Name and surname</label>
        <input type="text" placeholder="Linda Devenport" class="form-control" formControlName="name" />
        <div *ngIf="submitted && f.name.errors" class="invalid-controls">
          <span *ngIf="f.name.errors.required">Name and surname are required.</span>
        </div>
      </div>
      <div class="form-group">
        <label>3. Email</label>
        <input type="email" placeholder="linda@gmail.com" class="form-control" formControlName="email" />
        <div *ngIf="submitted && f.email.errors" class="invalid-controls">
          <span *ngIf="f.email.errors.required">Email is required.</span>
          <span *ngIf="f.email.errors.email">Please Enter a valid email.</span>
        </div>
      </div>
      <!-- <div class="form-group">
        <label>4. Telephone</label>
        <div class="d-flex">
          <div class="dropdown custom_phone_drop">
            <button type="button" id="callDrop" data-bs-toggle="dropdown" aria-expanded="false"
              class="num_selecter border-0">
              <span>+{{ selectedCode }}</span>
            </button>

            <ul aria-labelledby="callDrop" class="dropdown-menu call_num_menu">
              <li *ngFor="let code of countryCodeList" (click)="onSelectCode(code)"
                [ngClass]="selectedCode === code.calling_code?'code-selected' : ''">
                <div class="country_name">
                  <span>{{ code.country }}</span>
                </div>
                <div class="country_number">
                  <span> +{{ code.calling_code }}</span>
                </div>
              </li>
            </ul>
          </div>
          <input type="text" placeholder="784395273" class="form-control border-0" formControlName="phone"
            (keypress)="onPress($event)" />
        </div>
        <div *ngIf="submitted && f.phone.errors" class="invalid-controls">
          <span *ngIf="f.phone.errors.required">Phone number is required.</span>
          <span *ngIf="f.phone.errors.pattern">Please Enter a valid phone number.</span>
        </div>
      </div> -->
      <div class="form-group">
        <label>4. Your message. What did you want to share with us?</label>
        <textarea name="" class="form-control custam_textarea contact_form" id="" rows="4" placeholder="Write details here..."
          formControlName="message"></textarea>
      </div>

      <div style="color: red">{{ commonErrorMessage }}</div>
      <div class="form-group">
        <a class="comman_anchor" (click)="submitForm()">Submit 
          <span
          *ngIf="submitLoader"
          class="mx-2 spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span></a>
      </div>
    </form>
  </div>
</ng-container>


<div class="contact_signup_form" *ngIf="paperShow">
  <form action="" [formGroup]="paperRequestForm">
    <div class="form-group">
      <label>1. Name and surname</label>
      <input type="text" placeholder="Linda Devenport" class="form-control" formControlName="nameSurname" />
      <div *ngIf="formSubmitted && form.nameSurname.errors" class="invalid-controls">
        <span *ngIf="form.nameSurname.errors.required">Name and surname are required.</span>
      </div>
    </div>
    <div class="form-group">
      <label>2. Organization that you represent</label>
      <input type="text" placeholder="Organization" class="form-control" formControlName="organization" />
      <div *ngIf="formSubmitted && form.organization.errors" class="invalid-controls">
        <span *ngIf="form.organization.errors.required">Please enter Organization.</span>
      </div>
    </div>
    <div class="form-group">
      <label>3. Professional email address</label>
      <input type="email" placeholder="linda@domain.com" class="form-control" formControlName="email" />
      <div *ngIf="formSubmitted && form.email.errors" class="invalid-controls">
        <span *ngIf="form.email.errors.required">Email is required.</span>
        <span *ngIf="form.email.errors.email">Please Enter a valid email.</span>
      </div>
    </div>
    <div class="form-group">
      <label>4. Job position</label>
      <input type="text" placeholder="Your job position" class="form-control" formControlName="job" />
      <div *ngIf="formSubmitted && form.job.errors" class="invalid-controls">
        <span *ngIf="form.job.errors.required">Job position is required.</span>
      </div>
    </div>

    <!-- <div style="color: red">{{ commonErrorMessage }}</div> -->
    <div class="form-group mt-3">
      <a class="comman_anchor" (click)="submitRequest()">Submit <span
        *ngIf="submitLoader"
        class="mx-2 spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span></a>
    </div>
  </form>
</div>