import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/services/common.service';
import { icons } from 'src/assets/img/svg/svg';

@Component({
  selector: 'app-personalised',
  templateUrl: './personalised.component.html',
  styleUrls: ['./personalised.component.scss']
})
export class PersonalisedComponent implements OnInit {
  @ViewChild("tipContent") tooltipContent!: any;
  @ViewChild("calButton") calButton!: ElementRef;
  @ViewChild("t") template!: NgbTooltip;
  public icons = icons;
  public stepList: any;
  private id!: string;
  public isLoading: boolean = false;
  public personalisDetails: any;
  public isTooltip:boolean = false;
  @HostListener('document:click', ['$event.target'])
  onDocumentClick(targetElement: HTMLElement): void {
    const clickedInside = this.tooltipContent && this.tooltipContent.elementRef.nativeElement.contains(targetElement);
    const calButton = this.calButton && this.calButton.nativeElement.contains(targetElement);
    if ((!clickedInside && this.template.isOpen() && !calButton) || !this.isTooltip) {
      this.template.close();
    }
    if (!clickedInside && this.template.isOpen() && !calButton) {
      this.onCalcuClick();
    }
  }

  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private router:Router
  ) {
    this.activatedRoute.params.subscribe((param: any) => {
      this.id = param.id;
    });
  }

  ngOnInit(): void {
    this.getPersonalised(this.id);
  }

  onCalcuClick(){
    this.isTooltip = !this.isTooltip;
  }

  onPopupClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  getPersonalised(id: string) {
    this.isLoading = true;
    this.commonService.getPersonalised(id).subscribe({
      next: (res: any) => {
        this.personalisDetails = res;
        const businessName = this.personalisDetails?.business_name?.toLowerCase() ;
        this.personalisDetails.business_name = businessName.includes('accommodation') ? 'hotel' : businessName.includes('restaurants') ? 'restaurant' : '';
        this.isLoading = false;
        this.stepList = [
          "Your precise Greena Index, which reflects your current level of positive impact.",
          `A comparison of how you stack up against other ${this.personalisDetails.business_name.toLowerCase()} in our network.`,
          "Information on how to access personalised, step-by-step guidance for utilising your current sustainability efforts to attract more guests and reduce costs."
        ];
      },
      error: (err: any) => {
        if(err.status === 404){
          this.router.navigate(['/']);
        } else {
          this.messageService.add({
            severity: "error",
            detail: "Something went wrong",
          });
        }
        this.isLoading = false;
      }
    })
  }

  public gotoOtherPage(route:string){
    if (typeof window !== "undefined") {
      window.open(`${route}`,"_blank");
    }
  }

}
