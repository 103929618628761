import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { icons } from 'src/assets/img/svg/svg';
import { BlinkingTextInterface } from '../blinking-text/blinking-text.interface';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, AfterViewInit {
  public icon = icons;
  public vendorID!: string;
  private subscriptions: Subscription[] = [];
  wordArray = ["Restaurants" , "Hotels"];
  tempInnerText: string = 'Hotels';
  blinkingTextInfo: BlinkingTextInterface = {
    textColor: "#ffffff", 
    blinkWidth: "5px",
    typingSpeedMilliseconds: 40,
    deleteSpeedMilliseconds: 40,
  };
  innerText = ''

  private i = 0;

  constructor(private router: Router) {
    if (typeof window !== "undefined") {
      this.typingEffect();
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (typeof window !== "undefined" && !this.innerText) {
      const intrval = setInterval(() => {
        this.tempInnerText = this.tempInnerText === 'Hotels' ? "Restaurants" : 'Hotels';
        if (this.innerText) {
          clearInterval(intrval);
        }
      }, 2000);
    }
  }

  signUp() {
    this.router.navigate(['/auth/signup']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  private typingEffect(): void {
    const word = this.wordArray[this.i].split("");
    const loopTyping = () => {
      if (word.length > 0) {
        this.innerText += word.shift();
      } else {
        setTimeout(() => {
          this.deletingEffect();
        }, 2000);
        return;
      }
      setTimeout(loopTyping, this.blinkingTextInfo.typingSpeedMilliseconds);
    };
    loopTyping();
  }

  private deletingEffect(): void {
    const word = this.wordArray[this.i].split("");
    const loopDeleting = () => {
      if (word.length > 0) {
        word.pop();
        this.innerText = word.join("");
      } else {
        if (this.wordArray.length > this.i + 1) {
          this.i++;
        } else {
          this.i = 0;
        }
        this.typingEffect();
        return;
      }
      setTimeout(loopDeleting, this.blinkingTextInfo.deleteSpeedMilliseconds);
    };
    loopDeleting();
  }

}
