<div id="cover-spin" *ngIf="isLoading"></div>
<div class="privacy_policy_section">
    <div class="container">
      <div class="policy_header">
        <div class="left_wrap">
          <h3 class="privacy_title">Terms & Conditions</h3>
        </div>
        <div class="right_wrap">
          <ul class="icon_list">
            <li>
              <a href="javascript:;">
                <img src="assets/img/svg/global_icon.svg" alt="global" loading="lazy">
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <img src="assets/img/svg/footprint_icon.svg" alt="footprint_icon" loading="lazy">
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <img src="assets/img/svg/temple_icon.svg" alt="temple_icon" loading="lazy">
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <img src="assets/img/svg/shopping_cart_icon.svg" alt="shopping_cart_icon" loading="lazy">
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <img src="assets/img/svg/plant_icon.svg" alt="plant_icon" loading="lazy">
              </a>
            </li>
            <li>
              <a href="javascript:;">
                <img src="assets/img/svg/solid_group_icon.svg" alt="user_group_icon" loading="lazy">
              </a>
            </li>
            <li>
              <a href="javascript:;" class="active">
                <img src="assets/img/svg/shield_icon.svg" alt="shield_icon" loading="lazy">
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="policy-content-area">
        <div class="content-area-inner">
          <div class="content-left-wrap">
            <div class="content-list-dropdown" [ngClass]="windowWidth < 768 && !isOpenMenu ? 'show' : ''  ">
              <h4 class="content-list-title-btn" (click)="openDropdown()" [innerHTML]="tableTitle">{{tableTitle}}</h4>
              <ul class="table-content-list">
                <li *ngFor="let item of tableContentlist">
                  <a [ngClass]="{ active: activeTab === item.id }" (click)="moveToContent(item.id,item.section_heading)">
                    <h6 [innerHTML]="item.section_heading | safe: 'html'"></h6>
                  </a>
                </li>
              </ul>
            </div>
          </div>
  
          <div class="content-right-wrap">
            <div class="content-tab" id={{content.section_heading}} *ngFor="let content of tableContentlist">
              <p class="content-main-heading" [innerHTML]="content.section_heading | safe: 'html'"></p>
              <p [innerHTML]="content.terms_and_conditions_description | safe: 'html'"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>