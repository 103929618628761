import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import {
  of,
  throwError as observableThrowError,
  BehaviorSubject,
  Observable,
} from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { dashboardmainTextRes } from "../_interfaces/vendor.model";
import { Router } from "@angular/router";
import { EnvironmentalData, GuestDetails, ResDisputeData, ResponseVerification, Sendresponse } from "../_interfaces/common.model";

@Injectable({
  providedIn: "root",
})
export class VendorsService {
  baseUrl: any = environment.baseURL;
  selectedLanguage = new BehaviorSubject("en");
  questionnaireSubject: BehaviorSubject<{}>;
  questionnaire: Observable<any>;
  language!: string;
  public vendorID!: string;
  public loading = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient, private router: Router) {
    this.questionnaireSubject = new BehaviorSubject<any>({});
    this.questionnaire = this.questionnaireSubject.asObservable();
    this.selectedLanguage.subscribe((response) => {
      this.language = response;
      if (response) {
        this.language = response;
      } else {
        this.language = localStorage.getItem("langauge") as string;
      }
    });
  }
  private handleError(error: HttpErrorResponse) {
    return observableThrowError(error || "Server error");
  }

  signUp() {
    this.vendorID = localStorage.getItem("vendorUUID") as string;
    if (this.vendorID) {
      this.getVendorSignup(this.vendorID);
    } else {
      this.generateVendorUUID().subscribe((res) => {
        if (res) {
          this.vendorID = res.UUID;
          localStorage.setItem("vendorUUID", this.vendorID);
          this.router.navigate(["/auth"]);
        }
      });
    }
  }

  private getVendorSignup(vendorId: string) {
    this.router.navigate(["/auth"]);
  }

  generateVendorUUID(): Observable<any> {
    return this.http.post<any>(this.baseUrl + "vendor-survey/", "").pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getVendorContent(): Observable<any> {
    return this.http
      .get<any>(
        this.baseUrl + "vendor-survey/" + this.language + "/starterpage/"
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getQuestionsList(businessId: string): Observable<any> {
    return this.http
      .get<any>(
        this.baseUrl +
          "vendor-survey/" +
          this.language +
          "/get-questions/" +
          businessId +
          "/"
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getVendorsAnswersById(uuid: string, businessId: string): Observable<any> {
    return this.http
      .get<any>(
        this.baseUrl +
          "vendor-survey/" +
          this.language +
          "/" +
          uuid +
          "/" +
          businessId +
          "/answer/"
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  addQuestion(data: any, uuid: string, businessId: string): Observable<any> {
    return this.http
      .put<any>(
        this.baseUrl +
          "vendor-survey/" +
          this.language +
          "/" +
          uuid +
          "/" +
          businessId +
          "/answer/",
        data
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  getVendorInfoDetail(uuid: string): Observable<any> {
    return this.http
      .get<any>(
        this.baseUrl +
          "vendor-survey/" +
          this.language +
          "/" +
          uuid +
          "/signup/"
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  submitVendorDetails(data: any, uuid: string): Observable<any> {
    return this.http
      .put<any>(
        this.baseUrl +
          "vendor-survey/" +
          this.language +
          "/" +
          uuid +
          "/signup/",
        data
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  getAveragePoints(uuid: string, businessId: string): Observable<any> {
    return this.http
      .get<any>(
        this.baseUrl +
          "vendor-survey/" +
          uuid +
          "/" +
          businessId +
          "/survey-index/"
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  // getDashboardLetter(): Observable<any> {
  //   return this.http
  //     .get<any>(this.baseUrl + "survey/" + this.language + "/dashboard/")
  //     .pipe(
  //       map((res) => {
  //         return res;
  //       }),
  //       catchError(this.handleError)
  //     );
  // }

  getdashboardText(vendorId: string): Observable<dashboardmainTextRes> {
    return this.http
      .get<dashboardmainTextRes>(
        this.baseUrl + "vendor-survey/" + vendorId + "/get-vendor-email/"
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  bonusQuestion(
    businessId: string,
    is_bouns_question: string
  ): Observable<any> {
    // let queryParams = new HttpParams();
    // queryParams = queryParams.append("is_bonus_question",True);
    return this.http
      .get<any>(
        this.baseUrl +
          "vendor-survey/" +
          this.language +
          "/get-questions/" +
          businessId +
          `/?is_bonus_question=${is_bouns_question}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  updateBusiness(uuid: string, data: any): Observable<any> {
    return this.http
      .put<any>(
        this.baseUrl + "vendor-survey/" + uuid + "/update-business/",
        data
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  verifiedEmail(data: any) {
    return this.http.put(this.baseUrl + "vendor-survey/verify-email/", data);
  }

  guestDetails(id:string):Observable<GuestDetails>{
    return this.http.get<GuestDetails>(`${this.baseUrl}vendor-survey/creationtool-vendor-detail/${id}/`)
  }

  environmentData(id:string):Observable<EnvironmentalData[]>{
    return this.http.get<EnvironmentalData[]>(`${this.baseUrl}vendor-survey/creationtool-customer-id-detail/${id}/`);
  }

  sendDisputeData(data:FormData):Observable<ResDisputeData>{
    return this.http.post<ResDisputeData>(`${this.baseUrl}contacts/creationtool-dispute/`,data);
  }

  public sendReq(data:Sendresponse,id:string):Observable<ResponseVerification>{
    return this.http.put<ResponseVerification>(`${this.baseUrl}vendor-survey/${id}/request/`,data)
  }

  public guestAcceptTerms(id:number):Observable<any>{
    return this.http.patch<any>(`${this.baseUrl}vendor-survey/accept-terms-and-conditions/${id}/`,'')
  }
}
