import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Meta } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  baseUrl: any = environment.baseURL;

  constructor(
    private http: HttpClient,
    private metaService: Meta,
    ) {}

  private handleError(error: HttpErrorResponse) {
    return observableThrowError(error || "Server error");
  }

  getExtDisplay(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "survey/ext-display-text/").pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  /**
   * The `setSeoTags` function updates the meta tags for SEO purposes, including the title,
   * description, and image.
   * @param {string} [title] - The title parameter is used to set the title of the webpage. If a value
   * is provided, it will be used as the og:title (Open Graph title) property. Otherwise, a default
   * value of "Greena World - We make tourism green and sustainable." will be used.
   * @param {string} [description] - The description parameter is a string that represents the
   * description of the webpage. It is used for the Open Graph (og:description) and the regular meta
   * description tag. If no description is provided, a default description will be used.
   * @param {string} [image] - The `image` parameter is used to specify the URL of the image that will
   * be displayed when sharing the page on social media platforms. If no `image` parameter is provided,
   * a default image URL is used.
   */
  public setSeoTags(title?: string, description?: string, image?: string) {
    this.metaService.updateTag({
      property: "og:title",
      content: title || "Greena World - We make tourism green and sustainable.",
    });

    this.metaService.updateTag({
      property: "twitter:title",
      content: title || "Greena World - We make tourism green and sustainable.",
    });
    
    this.metaService.updateTag({
      property: "og:description",
      content: description || "Discover eco-friendly accommodation, restaurants and outdoor activities and get rewarded for traveling responsibly.",
    });

    this.metaService.updateTag({
      property: "twitter:description",
      content: description || "Discover eco-friendly accommodation, restaurants and outdoor activities and get rewarded for traveling responsibly.",
    });
    
    this.metaService.updateTag({
      name: "description",
      content: description || "Discover eco-friendly accommodation, restaurants and outdoor activities and get rewarded for traveling responsibly.",
    });
    
    this.metaService.updateTag({
      property: "og:image",
      content: image || "https://admin.greena.world/media/Survey%20Preview%20Image/metaImage.jpeg",
    });

    this.metaService.updateTag({
      property: "twitter:image",
      content: image || "https://admin.greena.world/media/Survey%20Preview%20Image/metaImage.jpeg",
    });
  }
}
