import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MediaData, VendorsDetails } from "src/app/_interfaces/common.model";
import { CommonService } from "src/app/services/common.service";
import { icons } from "src/assets/img/svg/svg";

@Component({
  selector: "app-prospective-guests",
  templateUrl: "./prospective-guests.component.html",
  styleUrls: ["./prospective-guests.component.scss"],
})
export class ProspectiveGuestsComponent implements OnInit {
  @Input() stopScrolling:boolean = false;
  @Input() isPersonalized : boolean = false;

  public icon = icons;
  public activeTab = 1;
  public isClick: boolean = false;
  public isMobileView:boolean = false;

  public venderDetails: VendorsDetails[] = []

  public prMediaData:MediaData[]=[
    {
      image1:'../../../assets//img/building.svg',
      title:'Your Logo',
      icon:this.icon['closeArrow'],
      image2:'../../../assets/img/logo-white.svg',
      date:'12th March, 2023',
      hotel_name:'Hotel Aurora',
      content:'joins Greena World to showcase its sustainability achivements.',
      btn_name1:'View press release',
      btn_icon1:this.icon['crossArrow'],
      btn_name2:'Download',
      btn_icon2:this.icon['download']
    },
    {
      image1:'../../../assets//img/building.svg',
      title:'Your Logo',
      icon:this.icon['closeArrow'],
      image2:'../../../assets/img/logo-white.svg',
      date:'12th March, 2023',
      hotel_name:'Hotel Aurora',
      content:'officially recognised by Greena World as the most eco-friendly hotel in London.',
      btn_name1:'View press release',
      btn_icon1:this.icon['crossArrow'],
      btn_name2:'Download',
      btn_icon2:this.icon['download']
    },
    {
      image1:'../../../assets//img/building.svg',
      title:'Your Logo',
      icon:this.icon['closeArrow'],
      image2:'../../../assets/img/logo-white.svg',
      date:'12th March, 2023',
      hotel_name:'Hotel Aurora',
      content:'receives official recognition from Greena World for its exceptional staff treatment.',
      btn_name1:'View press release',
      btn_icon1:this.icon['crossArrow'],
      btn_name2:'Download',
      btn_icon2:this.icon['download']
    },
    {
      image1:'../../../assets//img/building.svg',
      title:'Your Logo',
      icon:this.icon['closeArrow'],
      image2:'../../../assets/img/logo-white.svg',
      date:'12th March, 2023',
      hotel_name:'Hotel Aurora',
      content:'Here are five strategies Hotel Aurora uses to positively impact their local community.',
      btn_name1:'Read more',
      btn_icon1:this.icon['crossArrow'],
    },
  ]
  
  constructor(public router:Router,private commonService:CommonService,private activatedRoute:ActivatedRoute) {
  }

  ngOnInit(): void {
    // if(typeof window !== "undefined"){
    //   setInterval(() => {
    //     if (!this.isClick && !this.stopScrolling) {
    //       this.changeTab();
    //     }
    //   }, 3000);
    // }
    // this.activatedRoute.queryParams.subscribe((res:any)=>{
    //   if(res.section){
    //     setTimeout(() => {
    //       this.commonService.scrollTop('auto-change-section');
    //     }, 1000);
    //   }
    // })
    if (typeof window !== "undefined") {
      this.isMobileView = window.innerWidth <= 768;
    }
    this.venderDetails = [
      {
        title: 'Eco',
        value: 68,
        content: [
          {
            value: 'All unnecessary single-use plastics have been eliminated.',
            icon:this.icon['no_plastics']
          },
          {
            value: 'Partially powered by on-site renewable energy.',
            icon:this.icon['fan']
          }, {
            value: 'Recycling facilities available on site.',
            icon:this.icon['energy_green']
          },
        ]
      },
      {
        title: 'Social',
        value: 92,
        content: [
          {
            value: 'Local artists and entrepreneurs are supported here.',
            icon:this.icon['supports']
          },
          {
            value: 'Gender pay equality ensured.',
            icon:this.icon['equality']

          }, {
            value: 'Empowering people through skill training opportunities.',
            icon:this.icon['cap']
          },
        ]
      },
      {
        title: 'Wellbeing',
        value: 88,
        content: [
          {
            value: 'Green spaces available for guest relaxation.',
            icon:this.icon['outdoor']

          },
          {
            value: 'Excellent level of cleanliness, as rated by guests.',
            icon:this.icon['clienliness']

          }, {
            value: 'A majority of menu ingredients are organic.',
            icon:this.icon['organic_pros']

          },
        ]
      },
    ];
  }

  private changeTab() {
    if (this.activeTab === 5) {
      this.activeTab = 1;
    } else {
      this.activeTab++;
    }
  }

  public setActiveTab(tabNumber: number, event?: any) {
    if (event.isTrusted) {
      this.isClick = event.isTrusted;
      this.commonService.scrollTop('auto-change-section');
    }
    this.activeTab = tabNumber;
  }

  public goto(data:string){
    this.commonService.onGoto(data);
  }
}
