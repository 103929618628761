<div id="auto-change-section" class="prospective-content">
  <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item" [class.active]="activeTab === 1">
      <button [ngClass]="activeTab === 1 ? 'nav-link active' : 'nav-link'" id="online-offline"
        (click)="setActiveTab(1, $event)">
        Guest dashboard
      </button>
    </li>
    <li class="nav-item" [class.active]="activeTab === 2">
      <button [ngClass]="activeTab === 2 ? 'nav-link active' : 'nav-link'" id="social-media"
        (click)="setActiveTab(2, $event)">
        Social media
      </button>
    </li>
    <li class="nav-item" [class.active]="activeTab === 3">
      <button [ngClass]="activeTab === 3 ? 'nav-link active' : 'nav-link'" id="specific-unit"
        (click)="setActiveTab(3, $event)">
        In a designated area
      </button>
    </li>
    <li class="nav-item" [class.active]="activeTab === 4">
      <button [ngClass]="activeTab === 4 ? 'nav-link active' : 'nav-link'" id="your-website"
        (click)="setActiveTab(4, $event)">
        Your website
      </button>
    </li>
    <li class="nav-item" [class.active]="activeTab === 5">
      <button [ngClass]="activeTab === 5 ? 'nav-link active' : 'nav-link'" id="booking-platform"
        (click)="setActiveTab(5, $event)">
        Booking platforms
      </button>
    </li>
    <li class="nav-item" [class.active]="activeTab === 6">
      <button [ngClass]="activeTab === 6 ? 'nav-link active' : 'nav-link'" id="PR-media-pane"
        (click)="setActiveTab(6, $event)">
        PR & Media
      </button>
    </li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane" id="online-offline-pane" [class.active]="activeTab === 1">
      <div class="row justify-content-center">
        <div class="col-lg-12" [ngClass]="isPersonalized ? 'col-xl-12' : 'col-xl-10'">
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="index-qr">
                <img src="./assets/img/svg/hotel-aurora2.png" alt="hotel logo" loading="lazy"/>
              </div>
            </div>
            <div class="col-lg-1 col-12 px-0">
              <div class="online-to-offline-arrow">
                <i [innerHTML]="icon['ArrowRight'] | safe"></i>
                <i [innerHTML]="icon['ArrowRight'] | safe"></i>
                <i [innerHTML]="icon['ArrowRight'] | safe"></i>
                <i [innerHTML]="icon['ArrowRight'] | safe"></i>
              </div>
            </div>
            <div class="col-lg-7 col-12 offline-card-wrapper" [ngClass]="{'inner-card':router.url.includes('vendors')}">
              <div class="offline-card">
                <div class="offline-card-content">
                  <div class="offline-head">
                    <div class="hotel-title">
                      <h3 class="text-primary">Hotel Aurora</h3>
                      <p>
                        <span [innerHTML]="icon['MapPin'] | safe"></span>22 Park
                        Lane, London, United Kingdom
                      </p>
                    </div>
                    <!-- <div class="hotel-img">
                      <img src="./assets/img/hotel-aurora.jpeg" alt="" />
                    </div> -->
                    <ul class="signup_nav">
                      <li class="nav-item">
                        <a class="signup-link">
                          <div class="img-wrapper">
                            <img src="assets/img/svg/english.svg" alt="logo" loading="lazy">
                          </div>
                          <svg class="arrow" width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_653_4229)">
                              <path
                                d="M10.0001 10.9766L14.1251 6.85156L15.3034 8.0299L10.0001 13.3332L4.69678 8.0299L5.87511 6.85156L10.0001 10.9766Z"
                                fill="#929292" />
                            </g>
                            <defs>
                              <clipPath id="clip0_653_4229">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="communities">
                    <div class="communities-area">
                      <i [innerHTML]="icon['greenaLogoIcon'] | safe"></i>
                      <h5>75<span>/100</span></h5>
                    </div>
                    <div class="communities-desc">
                      <p>
                        The level of positive impact this business has on the
                        planet and its people. <a (click)="goto('greenaIndex')">Learn more</a>
                      </p>
                    </div>
                  </div>

                  <div class="offline-desc">
                    <p>
                      Nestled in the heart of a pristine landscape, Aurora Hotel
                      has been a pioneer of sustainability since its founding in
                      1956. Inspired by the breathtaking surroundings, founders
                      Mick and Jane Burton initially focused on the well-being
                      of their family, later extending their vision to include
                      both guests and the local community, creating a haven that
                      respects nature.
                    </p>
                  </div>
                </div>
                <div class="achievements">
                  <h6>
                    Here are details about this business's current sustainability achievements:
                  </h6>
                  <p>(Learn <a (click)="goto('verification')">here</a> how our verification process works)</p>
                </div>
                <div class="guest-category mt-1">
                  <div *ngFor="let value of venderDetails; let i=index" class="guest-card-wrapper">
                    <div class="score-details-wrapper">
                      <div class="d-flex score-details align-items-center">
                        <h4>{{value.title}}</h4>
                        <!-- <i [innerHTML]="icon['info_icon'] | safe"></i> -->
                      </div>
                      <ul class="eco-details" *ngFor="let option of value.content">
                        <li class="d-flex score-details align-items-center">
                          <div class="score-img-wrapper">
                            <i [innerHTML]="option.icon| safe"></i>
                          </div>
                          <span class="pros-content">
                            {{option?.value}}
                            <!-- <i class="info_icon me-0" [innerHTML]="icon['info_icon'] | safe"></i> -->
                          </span> 
                          <!-- <div class="score-img-wrapper">
                            
                            <span>{{option?.value}}</span>
                          </div>
                          <span>
                            
                          </span> -->
                        </li>
                      </ul>
                      <div class="line-wrapper">
                        <a class="view-more-btn">View more <i [innerHTML]="icon['arrowOutline'] | safe"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane" id="social-media-pane" [class.active]="activeTab === 2">
      <div class="social-media-posts">
        <div class="d-flex social-media-post-main">
          <div class="social-media-card">
            <div class="p-md-2 p-1 bg-white social-media-post-card">
              <img src="assets/img/social-media-post-1.1.png" alt="media post 1" loading="lazy"/>
            </div>
          </div>
          <div class="social-media-card">
            <div class="p-md-2 p-1 bg-white social-media-post-card">
              <img src="assets/img/social-media-post-1.2.png" alt="media post 2" loading="lazy"/>
            </div>
          </div>
          <div class="social-media-card" *ngIf="!isMobileView && !isPersonalized">
            <div class="p-md-2 p-1 bg-white social-media-post-card">
              <img src="assets/img/social-media-post-1.3.png" alt="media post 3" loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane" id="specific-unit-pane" [class.active]="activeTab === 3">
      <div class="social-media-posts">
        <div class="d-flex social-media-post-main receptionishit">
          <div class="social-media-post-column">
            <div class="p-md-2 p-1 bg-white social-media-post-card specific-unit-card">
              <div class="specific-unit-img-wrap">
                <div class="specific-unit-img">
                  <img src="../../../assets/img/specific-unit-image-1.1.png" alt="" loading="lazy"/>
                </div>
                <div class="specific-unit-img">
                  <img src="../../../assets/img/specific-unit-image-1.2.png" alt="" loading="lazy"/>
                </div>
              </div>
            </div>
          </div>
          <div class="social-media-post-column">
            <div class="p-md-2 p-1 bg-white social-media-post-card specific-unit-card">
              <div class="specific-unit-img-wrap">
                <div class="specific-unit-img">
                  <img src="assets/img/specific-unit-image-2.1.png" alt="" loading="lazy"/>
                </div>
                <div class="specific-unit-img">
                  <img src="assets/img/specific-unit-image-2.2.png" alt="" loading="lazy"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane" id="your-website-pane" [class.active]="activeTab === 4">
      <div class="your-website-wrapper">
        <div class="inner-wrapper">
          <div class="header-website">
            <div class="discover-impact">
              <h4>Discover our impact</h4>
              <i [innerHTML]="icon['crossArrow'] |safe"></i>
            </div>
            <div class="verified-logo">
              <span>Verified by:</span>
              <img src="assets/img/logo-white.svg" alt="logo" loading="lazy"/>
            </div>
          </div>
          <nav class="navbar navbar-expand-lg navbar-light website-nav">
            <div class="container-fluid p-0">
              <a class="navbar-brand"> <img src="assets/img/hotel-logo.png" alt="logo" loading="lazy"/></a>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page">Rooms</a>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                      aria-expanded="false">
                      Dining <i class="ms-2" [innerHTML]="icon['arrowDown'] | safe"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link">Spa</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link">Offers</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link">Location</a>
                  </li>
                </ul>
              </div>
              <a class="btn btn-gray">Book Now</a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div class="hotel-info">
        <a class="navbar-brand" href="#"> <img src="assets/img/hotel-logo.png" alt="logo" loading="lazy"/></a>
        <p>Nestled in the heart of a pristine landscape, Aurora Hotel has been a pioneer of sustainability since its
          founding in 1956. Inspired by the breathtaking surroundings, founders Mick and Jane Burton initially focused on
          the well-being of their family, later extending their vision to include both guests and the local community,
          creating a haven that respects nature.</p>
        <div class="social-link">
          <i [innerHTML]="icon['facebookOutline'] |safe"></i>
          <i [innerHTML]="icon['whatsAppOutline'] |safe"></i>
          <i [innerHTML]="icon['youtubeOutline'] |safe"></i>
        </div>
        <div class="header-website">
          <div class="discover-impact">
            <h4>Discover our impact <i [innerHTML]="icon['crossArrow'] |safe"></i></h4>
          </div>
          <div class="verified-logo">
            <span>Verified by:</span>
            <img src="assets/img/logo-white.svg" alt="logo" loading="lazy"/>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane" id="booking-platform-pane" [class.active]="activeTab === 5">
      <div class="d-flex justify-content-center">
        <div class="p-md-2 p-1 bg-white social-media-post-card specific-unit-card book-card-shadow">
          <img src="assets/img/Booking-Poster.png" alt="booking image" loading="lazy">
        </div>
      </div>
    </div>
    <div class="tab-pane" id="PR-media-pane-pane" [class.active]="activeTab === 6">
      <div class="d-flex flex-wrap pr-media-card-main">
        <div class="pr-media-box" *ngFor="let data of prMediaData,let l=last,let i=index">
          <div class="d-flex flex-column pr-media-card" *ngIf="i === 2 ? !isPersonalized : !(i === 3 && (!router.url.includes('/vendors/result/') || isMobileView ))">
            <div class="d-flex align-items-center justify-content-center pr-media-image">
              <div class="d-flex align-items-center">
                <div class="img-wrapper">
                  <img [src]="data?.image1" alt="logo" loading="lazy"/>
                </div>
                <h6 class="title">{{data?.title}}</h6>
                <i [innerHTML]="data.icon | safe"></i>
                <img [src]="data?.image2" class="white-logo" alt="'logo" loading="lazy"/>
              </div>
            </div>
            <div class="d-flex flex-column bg-white pr-media-card-body">
              <div class="inner-pr-card">
                <!-- <small>{{data?.date}}</small> -->
                <small>Press release</small>
                <h5 class="mt-1">
                  <span>{{data?.hotel_name}}</span> {{data?.content}}
                </h5>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-start flex-wrap button-group">
              <a class="btn arrow">{{data?.btn_name1}} <i [innerHTML]="data.btn_icon1 | safe"></i></a>
              <a class="btn me-0 arrow" *ngIf="!l">{{data?.btn_name2}} <i [innerHTML]="data?.btn_icon2 | safe"></i></a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>