import { icons } from "./../../../assets/img/svg/svg";
import { Router } from "@angular/router";
import { SeoService } from "./../../services/seo.service";
import { SurveyService } from "../../services/survey.service";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { MessageService } from "primeng/api";
import { GreenaIndexData } from "../interface/greena-index";
import { Endorsers } from "src/app/_interfaces/survey.model";

@Component({
  selector: "app-glx-page",
  templateUrl: "./glx-page.component.html",
  styleUrls: ["./glx-page.component.scss"],
  providers: [MessageService],
})
export class GlxPageComponent implements OnInit, AfterViewInit {
  public icons = icons;
  isOpenMenu: boolean = false;
  displayPaper: boolean = false;
  public isLoading:boolean = false;
  public frontText!: string;
  public endoresArray: Endorsers[] = [];
  uuid: string = "";
  isShowMore = false;
  language!: string;
  glxTitle!: string;
  glxDescription!: string;
  glxImage!: string;

  greenaindex: OwlOptions = {
    navText: [
      '<i class="bi bi-chevron-left"></i>',
      '<i class="bi bi-chevron-right"></i>',
    ],
    responsive: {
      0: {
        margin: 16,
        items: 2,
        touchDrag: true,
        mouseDrag: true,
        nav: true,
        dots: false,
      },
      500: {
        margin: 20,
        items: 2,
        touchDrag: true,
        mouseDrag: true,
        nav: true,
        dots: false
      },
      991: {
        margin: 20,
        items: 3,
        touchDrag: true,
        mouseDrag: true,
        nav: true,
        dots: false,
      },
      1200: {
        margin: 24,
        items: 4,
        touchDrag: true,
        mouseDrag: true,
        nav: true,
        dots: false,
      },
    },
  };

  public greenaScore:GreenaIndexData[]=[
    {
      icon:this.icons['greena_score'],
      content:'We welcome hotels and restaurants that express their commitment to having a positive impact on the planet, local communities, and guest well-being. These businesses are starting their sustainability journey with an expressed commitment to positive change in the way they work.'
    },
    {
      icon:this.icons['greena_score_2650'],
      content:'These businesses are already making a promising positive impact and are embarking on their sustainability journey with a strong desire for further improvement.'
    },
    {
      icon:this.icons['greena_score_5157'],
      content:'Sustainability is an important part of their business, and they are already making a great positive impact on the environment and local communities.'
    },
    {
      icon:this.icons['greena_score_76100'],
      content:`Sustainability is at the heart of their day-to-day business practices, and they make a powerful contribution to the fight against climate change, while promoting guests' well-being and supporting local communities.`
    }
  ];
  public isLoaded:boolean = false;

  constructor(
    private surveyService: SurveyService,
    private SeoService: SeoService,
    private router: Router
  ) {
    this.SeoService.getExtDisplay().subscribe((res: any) => {
      if (res) {
        const seoInfo = res;
        this.glxTitle = seoInfo.greena_index_page_listing_text;
        this.glxDescription = seoInfo.greena_index_page_meta_description;
        this.glxImage = seoInfo.greena_index_page_preview_image;
        this.SeoService.setSeoTags(this.glxTitle,this.glxDescription,this.glxImage);
      }
    });

    
    // this.surveyService.selectedLanguage.subscribe((response) => {
    //   this.language = response;
    //   if (response) {
    //     this.language = response;
    //   } else {
    //     this.language = localStorage.getItem("langauge") as string;
    //   }
    // });
    this.getEndorsers();
    this.getFrontText();
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isLoaded = true;
    }, 1000);
  }

  paperRequest() {
    this.displayPaper = true;
    this.surveyService.paperOpenSubject.next(true);
  }

  close() {
    this.surveyService.dialogCloseSubject.next(true);
  }

  showText() {
    this.isShowMore = !this.isShowMore;
  }

  getFrontText() {
    this.isLoading = true;
    this.surveyService.getGlxFrontText().subscribe({
      next:(res)=>{
          this.frontText = res.gix_front_text;
          this.isLoading = false;
      },
      error:(err:any)=>{
        this.isLoading = false;
      }
    });
  }

  getEndorsers() {
    this.surveyService.getGlxEndorsed().subscribe((res) => {
      this.endoresArray = res;
    });
  }

  // calculateGreenaIndex() {
  //   let vendorUUID = localStorage.getItem("vendorUUID");
  //   if (vendorUUID) {
  //     this.getVendorAnswer(vendorUUID);
  //   } else {
  //     this.generateVendorUUID();
  //   }
  // }

  // generateVendorUUID(): void {
  //   const sb = this.vendorsService.generateVendorUUID().subscribe((res) => {
  //     if (res) {
  //       this.uuid = res.UUID;
  //       localStorage.setItem("vendorUUID", this.uuid);
  //       // if (localStorage.getItem("businessId")) {
  //       //   localStorage.removeItem("businessId");
  //       // }
  //       this.router.navigate([]).then((result) => {
  //         window.open("vendors/info/" + this.uuid, "_blank");
  //       });
  //     }
  //   });
  //   this.subscriptions.push(sb);
  // }

  // getVendorAnswer(vendorId: string) {
  //   this.router.navigate([]).then((result) => {
  //     window.open("vendors/info/" + vendorId, "_blank");
  //   });
  // }

  activeReferenceTab() {
    const data: HTMLElement | null = document.getElementById("reference-list");

    setTimeout(() => {
      data?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100);

    document.getElementsByTagName("HTML")[0].classList.remove("hideScroll");
  }

  public gotoSignpUp(){
    this.router.navigate(['/auth/signup']);
  }
}
