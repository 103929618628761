<div class="banner-wrap">
  <div class="container">
    <div class="row">
      <div>
        <div class="banner-text-info">
          <h2>Empowering
            <span class="title">
              {{ innerText ? innerText : tempInnerText }}
            </span>
            <span class="text blink">|</span>
            to Share <br class="d-md-block d-none" /> their Sustainability Story.
          </h2>
          <p>There's over 100 Million eco-conscious guests eager to hear it.</p>
          <button class="btn btn-white" (click)="signUp()">
            Sign up now
            <i [innerHTML]="icon['arrowLong'] | safe"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>