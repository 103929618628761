import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { BlinkingTextInterface } from "./blinking-text.interface";

@Component({
  selector: "app-blinking-text",
  templateUrl: "./blinking-text.component.html",
  styleUrls: ["./blinking-text.component.scss"],
})
export class BlinkingTextComponent implements OnInit {
  @ViewChild("textElement")
  textElement!: ElementRef;
  @ViewChild("blinkElement")
  blinkElement!: ElementRef;
  @Input()
  wordArray!: string[];
  @Input()
  textInfo!: BlinkingTextInterface;

  private i = 0;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    if(typeof window !== "undefined"){
      // this.initVariables();
      this.typingEffect();
    }
  }

  // private initVariables(): void {

  //     this.renderer.setStyle(
  //       this.textElement.nativeElement,
  //       "color",
  //       this.textInfo.textColor
  //     );

  //     this.renderer.setStyle(
  //       this.blinkElement.nativeElement,
  //       "border-right-width",
  //       this.textInfo.blinkWidth
  //     );
  //     this.renderer.setStyle(
  //       this.blinkElement.nativeElement,
  //       "border-right-color",
  //       this.textInfo.textColor
  //     );
  //     this.renderer.setStyle(
  //       this.textElement.nativeElement,
  //       "font-family",
  //       this.textInfo.fontFamily
  //     );
  //     this.renderer.setStyle(
  //       this.textElement.nativeElement,
  //       "font-size",
  //       this.textInfo.fontSize
  //     );
  //     this.renderer.setStyle(
  //       this.textElement.nativeElement,
  //       "line-height",
  //       this.textInfo.lineHeight
  //     );
  // }

  private typingEffect(): void {
    const word = this.wordArray[this.i].split("");
    const loopTyping = () => {
      if (word.length > 0) {
        if(this.textElement){
          this.textElement.nativeElement.innerHTML += word.shift();
        }
      } else {
        setTimeout(() => {
          this.deletingEffect();
        }, 2000);
        return;
      }
      setTimeout(loopTyping, this.textInfo.typingSpeedMilliseconds);
    };
    loopTyping();
  }

  private deletingEffect(): void {
    const word = this.wordArray[this.i].split("");
    const loopDeleting = () => {
      if (word.length > 0) {
        word.pop();
        this.textElement.nativeElement.innerHTML = word.join("");
      } else {
        if (this.wordArray.length > this.i + 1) {
          this.i++;
        } else {
          this.i = 0;
        }
        this.typingEffect();
        return;
      }
      setTimeout(loopDeleting, this.textInfo.deleteSpeedMilliseconds);
    };
    loopDeleting();
  }
}
