<p-toast></p-toast>
<header [class]="isVendor ? 'header-border header': isAboutus ? 'header about-header' : 'header'" #headerElement id="blog">
  <div [class]="isVendor ? 'small-container':'container'">
    <div class="main-manu">
      <div class="row">
        <div class="col-lg-12">
          <nav class="navbar navbar-expand-lg">
            <div class="container-fluid p-0">
              <a class="navbar-brand p-0 m-0" *ngIf="router.url.includes('blog')" href="http://www.greena.world/" target="_blank">
                <img [src]="isAboutus ? 'assets/img/logo-white.svg' : 'assets/img/greena_logo_green.svg'" alt="Greena World" loading="lazy"/>
              </a>
              <a class="navbar-brand p-0 m-0" *ngIf="!router.url.includes('blog')">
                <img [routerLink]="['/']" [src]="isAboutus ? 'assets/img/logo-white.svg' : 'assets/img/greena_logo_green.svg'" alt="Greena World" loading="lazy"/>
              </a>
              <div class="d-flex align-items-center">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" (click)="openMenu()"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <svg *ngIf="!isAboutus" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z" fill="#151414" />
                  </svg>
                  <svg *ngIf="isAboutus" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z" fill="#ffffff" />
                  </svg>
                </button>
                <div
                  [class]="isOpenMenu ? 'navbar-collapse navbar_menu justify-content-end show' : 'navbar-collapse navbar_menu justify-content-end'">
                  <div class="mobile_header">
                    <div class="mobile_header_inner">
                      <a class="close_btn" (click)="closeMenu()"><img src="assets/img/close.svg" height="24"
                          alt="Greena World" loading="lazy"/></a>
                    </div>
                  </div>
                  <div class="d-flex align-items-lg-center ms-auto menu_bar">
                    <ul class="navbar-nav menu_nav" #myDiv>
                      <li class="nav-item" *ngFor="let data of headerData">
                        <a [routerLink]="data.link" class='nav-link about-nav' (click)="closeMenu()"
                          [routerLinkActive]="data.active || ''" [routerLinkActiveOptions]="{exact:true}">{{data.lable}}</a>
                      </li>
                    </ul>
                    <ul #headerMenu class="signup_nav" [ngClass]="showOption ? 'menu-open':''">
                      <li class="nav-item">
                        <a class="signup-link" (click)="showMenu()">
                          <svg *ngIf="!isAboutus" class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_872_6557)">
                              <path
                                d="M4.16668 16.6667H15.8333V18.3334H4.16668V16.6667ZM10 15.0001C8.2319 15.0001 6.53621 14.2977 5.28596 13.0475C4.03572 11.7972 3.33334 10.1015 3.33334 8.33342C3.33334 6.5653 4.03572 4.86961 5.28596 3.61937C6.53621 2.36913 8.2319 1.66675 10 1.66675C11.7681 1.66675 13.4638 2.36913 14.7141 3.61937C15.9643 4.86961 16.6667 6.5653 16.6667 8.33342C16.6667 10.1015 15.9643 11.7972 14.7141 13.0475C13.4638 14.2977 11.7681 15.0001 10 15.0001Z"
                                fill="#38573C" />
                            </g>
                            <defs>
                              <clipPath id="clip0_872_6557">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <svg *ngIf="isAboutus" class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_872_6557)">
                            <path
                              d="M4.16668 16.6667H15.8333V18.3334H4.16668V16.6667ZM10 15.0001C8.2319 15.0001 6.53621 14.2977 5.28596 13.0475C4.03572 11.7972 3.33334 10.1015 3.33334 8.33342C3.33334 6.5653 4.03572 4.86961 5.28596 3.61937C6.53621 2.36913 8.2319 1.66675 10 1.66675C11.7681 1.66675 13.4638 2.36913 14.7141 3.61937C15.9643 4.86961 16.6667 6.5653 16.6667 8.33342C16.6667 10.1015 15.9643 11.7972 14.7141 13.0475C13.4638 14.2977 11.7681 15.0001 10 15.0001Z"
                              fill="#ffffff" />
                          </g>
                          <defs>
                            <clipPath id="clip0_872_6557">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                          <svg class="arrow" width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_653_4229)">
                              <path
                                d="M10.0001 10.9766L14.1251 6.85156L15.3034 8.0299L10.0001 13.3332L4.69678 8.0299L5.87511 6.85156L10.0001 10.9766Z"
                                fill="#929292" />
                            </g>
                            <defs>
                              <clipPath id="clip0_653_4229">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                        <div class="user-menu">
                          <ng-container *ngIf="!router.url.includes('vendors') && !router.url.includes('payment')">
                            <a class="nav-link" routerLink="/auth/login" (click)="closeMenu()">Log in
                              <img src="assets/img/right_arrow.svg" alt="right arrow" />
                            </a>
                            <a class="nav-link" routerLink="/auth/signup" (click)="closeMenu()">Sign up
                              <img src="assets/img/right_arrow.svg" alt="right arrow" loading="lazy"/>
                            </a>
                          </ng-container>
                          <ng-container *ngIf="router.url.includes('vendors')|| router.url.includes('payment')">
                            <div class="vender-dropdown">
                              <p><i [innerHTML]="icons['hotelIcon'] | safe"></i> {{hotelName}}</p>
                              <ul>
                                <li> <a class="nav-link vender-link" (click)="goToPayment()">Billing
                                    <img src="assets/img/right_arrow.svg" alt="right arrow" loading="lazy"/>
                                  </a></li>
                                <li><a class="nav-link vender-link" (click)="open()">Contact us
                                    <img src="assets/img/right_arrow.svg" alt="right arrow" loading="lazy"/>
                                  </a></li>
                                <li><a class="nav-link vender-link" (click)="signOut()">Sign out
                                    <img src="assets/img/right_arrow.svg" alt="right arrow" loading="lazy"/>
                                  </a></li>
                              </ul>
                            </div>
                          </ng-container>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>

<p-dialog
header="To contact us, please fill in the form below:"
[(visible)]="displayContact"
[modal]="true"
[style]="{ width: '713px' }"
[draggable]="false"
class="contact_popup"
(onHide)="close()"
[closeOnEscape]="false"
>
<app-contact-us></app-contact-us>
</p-dialog>