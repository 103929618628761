import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  Input,
  HostListener,
} from "@angular/core";
import { SurveyService } from "src/app/services/survey.service";
import { BlinkingTextInterface } from "src/app/shared/blinking-text/blinking-text.interface";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { VendorsService } from "src/app/services/vendors.service";
import { HeaderTab } from "src/app/_interfaces/common.model";
import { icons } from "src/assets/img/svg/svg";
import { LocalstorageService } from "src/app/services/localstorage.service";
import { CommonService } from "src/app/services/common.service";
import { PaymentService } from "src/app/payment/service/payment.service";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [MessageService],
})
export class HeaderComponent implements OnInit {
  @ViewChild("headerMenu") headerMenu!: ElementRef;

  // new home page start
  public showOption: boolean = false;
  public icons = icons;
  public hotelName!: any;

  @HostListener("document:click", ["$event.target"])
  @HostListener("document:scroll", ["$event.target"])
  public onPageClick(targetElement: HTMLElement) {
    const clickedInside = this.headerMenu.nativeElement.contains(targetElement);
    if (!clickedInside) this.showOption = false;
  }
  // new home page end

  //old code start

  @Input() titleText!: string;
  @Input() titleText1!: string;
  @Input() buttonTitle!: string;
  // @Input() logoImage!: string;
  @Input() glxEndorsersLength!: number;
  @ViewChild("myDiv") divView!: ElementRef;
  @ViewChild("headerElement", { read: ElementRef, static: false })
  headerElement!: ElementRef;
  private subscriptions: Subscription[] = [];
  // blinkingWordArray: string[] = ["eco-friendly ", "sustainable ", "green "];
  // blinkingTextInfo: BlinkingTextInterface;
  uuid: string = "";
  travelID: string = "";
  vendorID: string|null;
  selectedImage: string = "";
  selectedCountry: string = "";
  options: string = "";
  detectedLocale = "";
  currentTab: number = 0;
  isOpenMenu: boolean = false;
  // isLoaderShow: boolean = false;
  isBtnDisable: boolean = false;
  detailsDataObj: any;
  displayContact: boolean = false;
  // selectedLanguage: string;
  currentRoute: string = "";
  // locale: string;
  isTraveller: boolean = false;
  isTeam: boolean = false;
  isGlx: boolean = false;
  isPrivacy: boolean = false;
  isSurvey: boolean = false;
  // public isVendor: boolean = false;
  // public isAboutus: boolean = false;
  @Input() isVendor!:boolean;
  @Input() isAboutus!:boolean;
  logoImage: string = "assets/img/greena_logo_green.svg";

  public headerData: HeaderTab[] = [
    {
      lable: "Greena Index",
      link: "/greena-index",
      active:'active'
    },
    {
      lable: "Benefits",
      link: "/benefits",
      active:'active'
    },
    {
      lable: "Pricing",
      link: "/pricing",
      active:'active'
    },
    {
      lable: "Verification",
      link: "/verification",
      active:'active'
    },
    {
      lable: "Showcases",
      link: "/showcases",
      // queryParams:true
      active:'active'
    },
    {
      lable: "About Us",
      active:'about',
      link: "/about-us",
    },
    {
      lable: "Resources",
      active:'active',
      link: "/blog",
    },
  ];

  constructor(
    private surveyService: SurveyService,
    public router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private vendorsService: VendorsService,
    private localstorageService:LocalstorageService,
    private commonService : CommonService,
    private paymentService: PaymentService
  ) {
    this.detectedLocale = this.getUsersLocale("en");
    // this.blinkingTextInfo = {
    //   textColor: "white",
    //   blinkWidth: "5px",
    //   typingSpeedMilliseconds: 40,
    //   deleteSpeedMilliseconds: 40,
    // };
    this.currentRoute = this.router.url;
    if (typeof window !== "undefined") {
      this.options = localStorage.getItem("surveyData") as string;
    }
    this.hotelName = this.localstorageService.getLocalStorage('hotal_name');
    this.vendorID = this.localstorageService.getLocalStorage('vendorUUID');
    this.surveyService.showPricingObs.subscribe((res: any) => {
      if(res){
        const index = this.headerData.findIndex((v:any)=> v.lable === 'Pricing');
        this.headerData.splice(index,1);
      }
    });
  }

  ngOnInit(): void {
    const routerSubscription = this.route?.queryParams.subscribe((value) => {
      let queryParams = value["about"];
      if (queryParams === "how") {
        this.scrollToId("how");
      } else if (queryParams === "benefits") {
        this.scrollToId("benefits");
      }
    });
    this.subscriptions.push(routerSubscription);
  }

  scrollToId(id: string) {
    const data: HTMLElement | null = document.getElementById(id);
    setTimeout(() => {
      data?.scrollIntoView({
        behavior: "smooth",
      });
    }, 300);
  }

  openMenu() {
    this.isOpenMenu = true;
    document.getElementsByTagName("HTML")[0].classList.add("hideScroll");
  }

  closeMenu() {
    this.isOpenMenu = false;
    document.getElementsByTagName("HTML")[0].classList.remove("hideScroll");
  }
  signOut(){
    this.closeMenu();
    this.router.navigate(['/']);
  }
  goToPayment(){
    this.commonService.gotoNewTab(`/payment/subscription/${this.vendorID}`);
    // this.paymentService.checkActivePlan(this.vendorID);
  }

  // scrollAction(event:any){
  //   if(event){
  //     setTimeout(() => {
  //       this.commonService.scrollTop("greenaIndex");
  //     }, 1300);
  //   }
  // }

  activeTab(id: number, scrollId: string = "") {
    const data: HTMLElement | null = document.getElementById(scrollId);

    setTimeout(() => {
      data?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100);

    this.currentTab = id;
    this.isOpenMenu = false;
    document.getElementsByTagName("HTML")[0].classList.remove("hideScroll");
  }

  onClick(scrollId: string = "") {
    const data: HTMLElement | null = document.getElementById(scrollId);
    setTimeout(() => {
      data?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100);
  }

  getUsersLocale(defaultValue: string): string {
    if (
      typeof window === "undefined" ||
      typeof window.navigator === "undefined"
    ) {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang;
  }

  // contactUs() {
  //   this.displayContact = true;
  // }

  close() {
    this.surveyService.dialogCloseSubject.next(true);
  }

  signUp() {
    document.getElementsByTagName("HTML")[0].classList.remove("hideScroll");
    this.vendorsService.signUp();
    this.vendorID = localStorage.getItem("vendorUUID") as string;
  }

  getAnswersDetail(UUID: string) {
    // this.isLoaderShow = true;
    this.isBtnDisable = true;
    if (typeof window !== "undefined") {
    if (localStorage.getItem("surveyData") === "E") {
      this.router.navigate(["vendors/info/" + this.vendorID], {
        queryParams: { fromPage: "home" },
      });
    } else {
      this.surveyService.getAnswersDetails(UUID).subscribe(
        (res) => {
          if (res?.status !== 404) {
            // this.isLoaderShow = false;
            this.detailsDataObj = res?.answers;
            if (this.detailsDataObj.length !== 0) {
              const totalCount = this.detailsDataObj.length;
              let stepId =
                this.detailsDataObj[this.detailsDataObj.length - 1].q_id;
              this.detailsDataObj.find((ele: any) => {
                if (ele.q_id === 1) {
                  if (
                    ele.option === "B" ||
                    ele.option === "C" ||
                    ele.option === "D" ||
                    ele.option === "G"
                  ) {
                    this.router.navigate(["/survey/signup/" + this.uuid]);
                  }
                }
              });
              const stepOption =
                this.detailsDataObj[this.detailsDataObj.length - 1].option;
              if (this.options === "A") {
                this.router.navigate(["/survey/signup/" + this.uuid]);
              }
              if (this.options === "F") {
                this.router.navigate(["/survey/signup/" + this.uuid]);
              }
              if (stepId === 1) {
                if (
                  stepOption === "B" ||
                  stepOption === "C" ||
                  stepOption === "D" ||
                  stepOption === "G"
                ) {
                  this.router.navigate(["/survey/signup/" + this.uuid]);
                } else if (stepOption === "G") {
                  let stepId = this.detailsDataObj[0].q_id;
                  if (stepId === 12) {
                    this.router.navigate([
                      "/survey/eco-trip/" + totalCount + "/" + this.uuid,
                    ]);
                  }
                }
              } else if (stepId === 8) {
                this.router.navigate(["/survey/signup/" + this.uuid]);
              } else if (stepId === 12 || stepId === 13) {
                this.router.navigate([
                  "/survey/eco-trip/" + totalCount + "/" + this.uuid,
                ]);
              }
            } else {
              this.router.navigate(["/survey/form/" + UUID]);
            }
          } else if (res?.status === 404) {
            this.generateUUID();
          }
        },
        (err) => {
          // this.isLoaderShow = false;
          console.log(err.message);
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong!!",
          });
        }
      );
    }
  }
  }

  generateUUID() {
    // this.isLoaderShow = true;
    const sb = this.surveyService.generateUUID().subscribe((res) => {
      if (res) {
        // this.isLoaderShow = false;
        if (this.isTraveller) {
          this.travelID = res.UUID;
          this.addTravelAnswer();
        } else {
          this.uuid = res.UUID;
          localStorage.setItem("UUID", res.UUID);
          this.router.navigate(["/survey/form/" + this.uuid]);
        }
      }
    });
    this.subscriptions.push(sb);
  }

  addTravelAnswer() {
    var travelData = {
      q_id: 1,
      q_text:
        "To kick off our relationship, please tell us which best describes you:",
      ans: "Eco-conscious traveler",
      option: "Traveller",
    };
    this.surveyService.addAnswer(travelData, this.travelID).subscribe(
      (res) => {
        this.router.navigate(["/survey/signup/" + this.travelID], {
          queryParams: { fromPage: "travel" },
        });
        localStorage.setItem("TravelUUID", this.travelID);
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Something Went Wrong!!",
        });
      }
    );
  }

  public open() {
    this.displayContact = true;
    // this.surveyService.paperOpenSubject.next(false);
    this.surveyService.paymentOpenSubject.next(true);
    this.showMenu();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
  //old code end

  // New home Page

  showMenu() {
    this.showOption = !this.showOption;
  }
}
