import { NgModule } from "@angular/core";
import { BrowserModule, Meta, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeModule } from "./home/home.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { TranslocoRootModule } from "./transloco-root.module";
import { TooltipModule } from "primeng/tooltip";
import { ToastModule } from "primeng/toast";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { CoreModule } from "./core/core.module";
import { PipeModule } from "./shared/pipe/pipe.module";
import { TermsConditionComponent } from "./terms-condition/terms-condition.component";
import { TermsEngagementComponent } from './terms-engagement/terms-engagement.component';

@NgModule({
  declarations: [AppComponent, PrivacyPolicyComponent,TermsConditionComponent, TermsEngagementComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    HomeModule,
    HttpClientModule,
    TranslocoRootModule,
    TooltipModule,
    ToastModule,
    CoreModule,
    PipeModule
  ],
  providers: [Title, Meta],
  bootstrap: [AppComponent],
})
export class AppModule { }
