<p-toast></p-toast>
<div id="cover-spin" *ngIf="isLoading"></div>
<div class="offline-card-wrapper">
  <div class="offline-card">
    <div class="offline-card-content">
      <div class="offline-card-cover-img" *ngIf="hotelDetails?.cover_img">
        <img [src]="hotelDetails?.cover_img" alt="cover image"/>
      </div>
      <div class="offline-profile-img mb-3" *ngIf="hotelDetails?.logo_img">
        <img [src]="hotelDetails?.logo_img" alt="logo"/>
      </div>
      <div class="offline-head">
        <div class="hotel-title">
          <h3 class="text-primary">{{hotelDetails?.name_of_vendor}}</h3>
          <p><span [innerHTML]="icon['MapPin'] | safe"></span>{{hotelDetails?.address}}</p>
        </div>
        <!-- <ul #languageMenu class="signup_nav" [ngClass]="showOption ? 'menu-open':''">
          <li class="nav-item">
            <a class="signup-link" (click)="showMenu()">
              <div class="img-wrapper">
                <img [src]="language.image" alt="logo" loading="lazy">
              </div>
              <svg class="arrow" width="20" height="20" viewBox="0 0 20 20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_653_4229)">
                  <path
                    d="M10.0001 10.9766L14.1251 6.85156L15.3034 8.0299L10.0001 13.3332L4.69678 8.0299L5.87511 6.85156L10.0001 10.9766Z"
                    fill="#929292" />
                </g>
                <defs>
                  <clipPath id="clip0_653_4229">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <div class="user-menu" (click)="closeMenu()">
              <a class="nav-link" *ngFor="let data of translate_language">
                <div class="d-flex align-items-center">
                  <div class="img-wrapper me-2">
                    <img [src]="data?.image" alt="logo" loading="lazy">
                  </div>{{data?.lable}}
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" [value]="data.language" [id]="data.language"
                    [checked]="data.language === language.language" [disabled]="data.language === language.language"
                    (change)="onSelectLanguage(data)">
                </div>
              </a>
            </div>
          </li>
        </ul> -->
      </div>
      <div class="communities" *ngIf="!hotelDetails?.display_index">
        <div class="communities-area">
          <i [innerHTML]="icon['greenaLogoIcon'] | safe"></i>
          <h5>{{hotelDetails?.greena_index}}<span>/100</span></h5>
        </div>
        <div class="communities-desc">
          <p>The level of positive impact this business has on the planet and local communities.<a (click)="onGoto('greenaIndex')">Learn more</a></p>
        </div>
      </div>
      <div class="offline-desc">
        <p>{{hotelDetails?.add_text}}</p>
      </div>
    </div>
    <div class="achievements">
      <h6>Here are details about this business's current sustainability achievements:</h6>
      <p>(Learn <a (click)="onGoto('verification')">here</a> how our verification process works)</p>
    </div>
    <div class="guest-category mt-1">
      <div *ngFor="let value of venderDetails; let i=index;trackBy: trackFn" class="guest-card-wrapper">
        <div class="score-details-wrapper">
          <div class="d-flex score-details align-items-center">
            <h4>{{value.title}}</h4>
            <i [innerHTML]="icon['info_icon'] | safe" [ngbTooltip]="tipContent"
              tooltipClass="guest-tooltip custom-tooltip" container="body"
              placement="top-left top-right bottom bottom-left bottom-right auto" triggers="click:blur" #t="ngbTooltip"
              (mouseenter)="onMouseEnter(t,questionIcon)" [autoClose]="false" #questionIcon (click)="onQuestionIconClick(questionIcon)"></i>
          </div>
          <ul class="eco-details" *ngFor="let option of value.options;">
            <li class="d-flex score-details align-items-center">
              <div class="score-img-wrapper">
                <img [src]="option?.icon" alt="logo" loading="lazy">
              </div>
              <span>
                {{option?.short_text_english}}
                <i class="info_icon me-0" [innerHTML]="icon['info_icon'] | safe" [ngbTooltip]="tipContent"
                  tooltipClass="custom-tooltip" container="body" placement="auto" triggers="click:blur" #t2="ngbTooltip"
                  (mouseenter)="onMouseEnter2(t2,optionIcon)" [autoClose]="false" (click)="onOptionIconClick(optionIcon)" #optionIcon></i>
              </span>
            </li>
            <ng-template #tipContent>
              <div class="tooltip-wrap" (click)="onPopupClick($event)">
                <!-- <div class="tooltip-content">
                  <p [innerHTML]="option?.longer_text_english"></p>
                </div> -->
                <div class="tooltip-content d-flex flex-column">
                  <div class="common-flex mb-2">
                    <i [innerHTML]="icon['info_fill_icon'] | safe"></i>
                    <i class="close-btn" [innerHTML]="icon['close_icon'] | safe" (click)="onClickClose2(t2)"></i>
                  </div>
                  <p class="mb-0 tooltip-detail" [innerHTML]="option?.longer_text_english"></p>
                </div>
              </div>
            </ng-template>
          </ul>
          <div class="line-wrapper">
            <a *ngIf="value.options?.length" class="view-more-btn" (click)="viewMore(value.category)"
              [ngClass]="{'more':value.expanded}">View {{!value.expanded ? 'more': 'less'}} <i
                [innerHTML]="icon['arrowOutline'] | safe"></i></a>
            <a *ngIf="!value.options?.length" class="view-more-btn">No record found</a>
          </div>
        </div>
        <ng-template #tipContent>
          <div class="tooltip-wrap d-flex flex-column" (click)="onPopupClick($event)" >
            <div class="common-flex mb-2">
              <i [innerHTML]="icon['info_fill_icon'] | safe"></i>
              <i class="close-btn" [innerHTML]="icon['close_icon'] | safe" (click)="onClickClose(t)"></i>
            </div>
            <div class="tooltip-content">
              <p class="mb-0 tooltip-detail">{{value?.tooltipContent}}</p>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="achievements-footer">
      <button class="btn btn-primary" (click)="open()">Dispute</button>
      <div class="d-flex footer-message"> <i [innerHTML]="icon['info_icon'] | safe"></i><span>You can dispute any of the
          above claims if you disagree with them.</span></div>
    </div>
  </div>
  <div class="description-footer">
    <p>Greena World Ltd, is a technology company based in London, United Kingdom. We use technology to make tourism good
      for environment, local communities and your health.
      <a href="/about-us" target="_blank">More info</a>
    </p>
  </div>
  <div class="policy-modal" *ngIf="!isAcceptPolicy && hotelDetails?.terms_and_conditions">
    <div class="policy-modal-inner">
      <h6>Before using Greena World, you must accept our:</h6>
      <ul>
        <li (click)="gotoPolicy('/terms-condition')">Terms and conditions </li>
        <li (click)="gotoPolicy('/privacy-policy')">Privacy & Cookie Policy</li>
      </ul>
      <button class="btn btn-primary" (click)="onAcceptPolicy(true)"><i [innerHTML]="icon['successArrow'] | safe" ></i> Accept</button>
      <button class="btn btn-outline-gray" (click)="onAcceptPolicy(false)"><i [innerHTML]="icon['close_icon'] | safe"></i> Decline</button>
    </div>
  </div>
</div>
<p-dialog header="Dispute form" [(visible)]="displayContact" [modal]="true" [style]="{ width: '440px' }"
  [draggable]="false" class="contact_popup" [closeOnEscape]="false" (onHide)="onDialogClose()">
  <p class="form-desc">We have a thorough verification process that hotels and restaurants must go through to obtain
    Greena certification. As a user, your feedback is crucial in holding businesses accountable and adhering to their
    verified Greena Index. If you notice any inconsistencies or discrepancies, please report them below. We are
    committed to investigating every issue raised to maintain transparency and accountability.</p>
  <form [formGroup]="desputeForm" class="dispute-form">
    <div class="form-group">
      <label class="mb-2 d-block">What claim are you disputing?</label>
      <div class="dropdown option_dropdown">
        <div class="option_selector guest" [ngClass]="{'drop-arrow' : isOpenMenu ,'invalid-error' : showError}"
          (click)="onClick()">
          <span class="dropdown-lable">{{ selectedAnswer }}</span>
          <i [innerHTML]="icon['arrowOutline'] | safe"></i>
        </div>
        <div class="invalid-controls">
          <span *ngIf="showError">{{errorMsg}}</span>
        </div>
        <div class="option" *ngIf="isOpenMenu">
          <div class="ul-wrapper" *ngFor="let data of venderDetails;let i=index">
            <ul>
              <h4 class="select-heading guest-dash-select-heading">{{data.title}}</h4>
              <li *ngFor="let option of data.options;let j=index"
                (click)="onSelectClaim(j,i,option.short_text_english)">{{option?.short_text_english}} <div
                  *ngIf="currentIndex === (i+1) && currentJindex ===  (j+1)" class="icon-wrapper"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" [ngClass]="fControl['dispute_details'].touched && fControl['dispute_details'].invalid ? 'invalid-error' : ''">
      <label class="mb-2">Please describe why you're disputing this claim</label>
      <div class="input-wrap">
        <textarea class="form-control" placeholder="Please explain why you are disputing the selected claim"
          id="exampleFormControlTextarea1" formControlName="dispute_details" rows="3"></textarea>
        <i *ngIf="fControl['dispute_details'].touched && fControl['dispute_details'].invalid" [innerHTML]="icon['ErrorInfo'] | safe"></i>
      </div>
      <div *ngIf="fControl['dispute_details'].touched && fControl['dispute_details'].invalid" class="invalid-controls">
        <span>Discription is required.</span>
      </div>
    </div>
    <div class="form-group" [ngClass]="fControl['name'].touched && fControl['name'].invalid ? 'invalid-error' : ''">
      <label class="mb-2">Your full name</label>
      <div class="input-wrap">
        <input type="text" placeholder="Enter your name" class="form-control" formControlName="name" />
        <i *ngIf="fControl['name'].touched && fControl['name'].invalid" [innerHTML]="icon['ErrorInfo'] | safe"></i>
      </div>
      <div *ngIf="fControl['name'].touched && fControl['name'].invalid" class="invalid-controls">
        <span>Name is required.</span>
      </div>
    </div>
    <div class="form-group" [ngClass]="fControl['email'].touched && fControl['email'].invalid ? 'invalid-error' : ''">
      <label class="mb-2">Your email address</label>
      <div class="input-wrap">
        <input type="text" placeholder="Enter email address" class="form-control" formControlName="email" />
        <i *ngIf="fControl['email'].touched && fControl['email'].invalid" [innerHTML]="icon['ErrorInfo'] | safe"></i>
      </div>
      <div *ngIf="fControl['email'].touched && fControl['email'].invalid" class="invalid-controls">
        <span>Email address is required.</span>
      </div>
    </div>
    <div class="form-group">
      <label class="mb-2 proof-lebal">Proof photos </label>
      <div class="input-wrap">
        <input type="file" multiple placeholder="Enter your proof_photo" class="form-control choose-file"
          formControlName="image" (change)="uploadFile($any($event.target).files)"
          accept=".jpeg,.jpg,.pdf,.webp,.png" />
        <div class="choose-file-content">
          <i [innerHTML]="icon['attechment'] | safe"></i>
          <p>Add up to 5 files (max 3MB each)</p>
        </div>
      </div>
      <div class="invalid-controls">
        <span *ngIf="isChooseFile">Please choose file</span>
      </div>
      <ng-container *ngIf="uploadedFiles.length">
        <ul class="img-selected-wrapper">
          <li class="img-selected" *ngFor="let file of uploadedFiles;let i=index">
            <p>{{ file.name }}</p>
            <i [innerHTML]="icon['trshButton'] | safe" (click)="onRemoveImage(i)"></i>
          </li>
        </ul>
      </ng-container>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button class="btn btn-primary" mat-raised-button type="submit" (click)="onDesputeSubmit()">
      <i [innerHTML]="icon['successArrow'] | safe"></i><span>Submit issue <span *ngIf="submitLoader"
          class="mx-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>
    </button>
  </ng-template>
</p-dialog>