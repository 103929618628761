import { VendorQrcodeComponent } from './shared/vendor-qrcode/vendor-qrcode.component';
import { VendorDashboardComponent } from './shared/vendor-dashboard/vendor-dashboard.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { GuestDashboardComponent } from './shared/guest-dashboard/guest-dashboard.component';
import { CommunicateComponent } from './shared/communicate/communicate.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PersonalisedComponent } from './shared/personalised/personalised.component';
import { TermsEngagementComponent } from './terms-engagement/terms-engagement.component';

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("../app/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "vendors",
    loadChildren: () =>
      import("../app/vendors/vendors.module").then((m) => m.VendorsModule),
  },
  {
    path: "survey",
    loadChildren: () =>
      import("./survey/survey.module").then((m) => m.SignupModule),
  },
  {
    path: "eco-travel",
    loadChildren: () =>
      import("./traveller/traveller.module").then((m) => m.TravellerModule),
  },
  {
    path: "greena-team",
    loadChildren: () => import("./team/team.module").then((m) => m.TeamModule),
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "terms-condition",
    component: TermsConditionComponent,
  },
  {
    path: "terms-engagement",
    component: TermsEngagementComponent,
  },
  {
    path: "vendor-dashboard",
    component: VendorDashboardComponent,
  },
  {
    path: "QRcode",
    component: VendorQrcodeComponent,
  },
  {
    path: "dashboard/:id",
    component: GuestDashboardComponent,
  },
  {
    path: "sustainability/:id",
    component: GuestDashboardComponent,
  },
  {
    path: "greena-index/:id",
    component: PersonalisedComponent,
  },
  {
    path: "communicate",
    component: CommunicateComponent,
  },
  { 
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) 
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule)
  },
  { path: 'verification', loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule) },
  { path: 'pricing', loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule) },
  { path: 'benefits', loadChildren: () => import('./benefits/benefits.module').then(m => m.BenefitsModule) },
  { path: 'payment', loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule) },
  { path: 'showcases', loadChildren: () => import('./showcases/showcases.module').then(m => m.ShowcasesModule) },
  {
    // Don't move this route from here
    path: ":id",
    component: PersonalisedComponent,
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
