import { VendorsService } from "src/app/services/vendors.service";
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SurveyService } from "src/app/services/survey.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { MessageService } from "primeng/api";
import { icons } from "src/assets/img/svg/svg";
import { BlogsService } from "../blog/services/blogs.service";
import { CommonService } from "../services/common.service";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { Location } from "@angular/common";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [MessageService],
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild("t") template!: NgbTooltip;
  public icon = icons;
  public blogList: any[] = [];
  public sustainabilityList = [
    {
      title: "£20,000",
      description: "/year saved on energy & water bills.",
      icon: this.icon["questionIcon"],
      tooltipContent:
        "Data from US Energy Information Administration and International Renewable Energy Agency (IRENA). Calculation based on a hotel with 50 rooms.",
    },
    {
      title: "70%",
      description: "increase in employee retention",
      icon: this.icon["questionIcon"],
      tooltipContent:
        "Governance & Accountability Institute interviewed over 1,000 employees.",
    },
    {
      title: "£160,000",
      description: "/year increase in bookings",
      icon: this.icon["questionIcon"],
      tooltipContent:
        "Cornell University, School of Hotel Administration research. Calculation based on a hotel with 50 rooms.",
    },
    {
      title: "19%",
      description:
        "Annual Return-on-Investment for Sustainable building projects.",
      icon: this.icon["questionIcon"],
      tooltipContent:
        "US Greena Building Council (USGBC) research on the business case for Green buildings.",
    },
  ];

  private subscriptions: Subscription[] = [];
  showPollutionTooltip: boolean = false;
  showWaterTooltip: boolean = false;
  isLoaderShowMovement: boolean = false;
  isBtnDisableMovement: boolean = false;
  isBtnDisableMission: boolean = false;
  isLoaderShowMission: boolean = false;
  isBtnDisableCard: boolean = false;
  isLoaderShowCard: boolean = false;
  isLoaderShowSignupSub: boolean = false;
  isBtnDisableCardSignupSub: boolean = false;
  isTraveller: boolean = false;
  isVendor: boolean = false;
  isSurvey: boolean = false;
  vendorID: string = "";
  uuid: string = "";
  amount: string = "";
  detailsDataObj: any;
  options: string = "";
  image: string = "assets/img/header-bg.jpg";
  cardImage1: string = "assets/img/cardfi1.jpg";
  cardImage2: string = "assets/img/cardfi2.jpg";
  benefitI1: string = "assets/img/tourists.svg";
  benefitI2: string = "assets/img/hotel2.svg";
  benefitI3: string = "assets/img/corporations.svg";
  benefitI4: string = "assets/img/environment.svg";
  travelID: string = "";
  stopScrolling: boolean = false;
  public isLoaded:boolean = false;

  public blogData: any[] = [];
  public networksList: any = [];
  private page: number = 1;
  private page_size: number = 10;

  public browserType!: string;
  public isMobile: boolean = false;
  // @HostListener("window:resize", ["$event"])
  // checkScreenSize() {
  //   this.isMobile = window.innerWidth <= 768;
  //   if (this.isMobile) {
  //     this.tooltipConfig.triggers = "manual";
  //   } else {
  //     this.tooltipConfig.triggers = "hover";
  //   }
  // }

  constructor(
    private surveyService: SurveyService,
    private router: Router,
    private messageService: MessageService,
    private vendorsService: VendorsService,
    private blogsService: BlogsService,
    private commonService: CommonService,
    private tooltipConfig: NgbTooltip,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (typeof window !== "undefined") {
      this.isMobile = window.innerWidth <= 768;
    }
    // this.getCardAvailability();
    if (typeof localStorage !== "undefined") {
      this.options = localStorage.getItem("surveyData") as string;
    }
    this.getBlogsList();
    this.getCompanyLogos();
    this.route.queryParams.subscribe((params: any) => {
      if (params.isPricing || params.section) {
        this.commonService.scrollTop("positive");
      } else {
        setTimeout(() => {
          this.commonService.scrollTop("blog");
        }, 1000);
      }
      if (params.calendly === 'true') {
        setTimeout(() => {
          this.commonService.scrollTop("calendy");
        }, 1000);
      }
    });
    this.location.replaceState('/');
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isLoaded = true;
    }, 2000);
  }

  getVendorSignup(vendorId: string, type: string) {
    this.loaderSection(type);
    this.router.navigate(["vendors/info/" + vendorId]);
  }

  loaderSection(type: string) {
    switch (type) {
      case "movement":
        this.isLoaderShowMovement = true;
        this.isBtnDisableMovement = true;
        break;
      case "mission":
        this.isLoaderShowMission = true;
        this.isBtnDisableMission = true;
        break;
      case "greena-card":
        this.isLoaderShowCard = true;
        this.isBtnDisableCard = true;
        break;
      case "signup_sub":
        this.isLoaderShowSignupSub = true;
        this.isBtnDisableCardSignupSub = true;
        break;
    }
  }

  generateVendorUUID(): void {
    const sb = this.vendorsService.generateVendorUUID().subscribe((res) => {
      if (res) {
        this.uuid = res.UUID;
        localStorage.setItem("vendorUUID", this.uuid);
        // if (localStorage.getItem("businessId")) {
        //   localStorage.removeItem("businessId");
        // }
        this.router.navigate(["vendors/info/" + this.uuid]);
      }
    });
    this.subscriptions.push(sb);
  }

  getAnswersDetail(UUID: string, type: string) {
    this.loaderSection(type);
    if (localStorage.getItem("surveyData") === "E") {
      this.router.navigate(["vendors/info/" + this.vendorID], {
        queryParams: { fromPage: "home" },
      });
    } else {
      this.surveyService.getAnswersDetails(UUID).subscribe(
        (res) => {
          if (res?.status !== 404) {
            this.isLoaderShowMovement = false;
            this.isLoaderShowMission = false;
            this.isLoaderShowCard = false;
            this.isLoaderShowSignupSub = false;
            this.detailsDataObj = res.answers;
            if (this.detailsDataObj.length !== 0) {
              const totalCount = this.detailsDataObj.length;
              let stepId =
                this.detailsDataObj[this.detailsDataObj.length - 1].q_id;
              this.detailsDataObj.find((ele: any) => {
                if (ele.q_id === 1) {
                  if (
                    ele.option === "B" ||
                    ele.option === "C" ||
                    ele.option === "D"
                  ) {
                    this.router.navigate(["/survey/signup/" + this.uuid]);
                  }
                }
              });
              const stepOption =
                this.detailsDataObj[this.detailsDataObj.length - 1].option;
              if (this.options === "A") {
                this.router.navigate(["/survey/signup/" + this.uuid]);
              }
              if (this.options === "F" || this.options === "E") {
                this.router.navigate(["/survey/signUpForm/" + this.uuid]);
              }
              if (this.options === "F") {
                this.router.navigate(["/survey/signup/" + this.uuid]);
              }
              if (this.options === "E") {
                this.router.navigate(["/survey/signUpForm/" + this.uuid]);
              }
              if (stepId === 1) {
                if (
                  stepOption === "B" ||
                  stepOption === "C" ||
                  stepOption === "D"
                ) {
                  this.router.navigate(["/survey/signup/" + this.uuid]);
                } else if (stepOption === "G") {
                  let stepId = this.detailsDataObj[0].q_id;
                  if (stepId === 12) {
                    this.router.navigate([
                      "/survey/eco-trip/" + totalCount + "/" + this.uuid,
                    ]);
                  }
                }
              } else if (stepId === 11) {
                this.router.navigate(["/survey/signup/" + this.uuid]);
              } else if (stepId === 12 || stepId === 13) {
                this.router.navigate([
                  "/survey/eco-trip/" + totalCount + "/" + this.uuid,
                ]);
              }
            } else {
              this.router.navigate(["/survey/form/" + UUID]);
            }
          } else if (res?.status === 404) {
            this.generateUUID(type);
          }
        },
        (err) => {
          this.isLoaderShowMovement = false;
          this.isLoaderShowMission = false;
          this.isLoaderShowCard = false;
          this.isLoaderShowSignupSub = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong!!",
          });
        }
      );
    }
  }

  getCardAvailability() {
    const sub = this.surveyService.getCartAvailability().subscribe((res) => {
      if (res) {
        this.amount = res.amount;
      }
    });
    this.subscriptions.push(sub);
  }

  generateUUID(type: string) {
    this.loaderSection(type);
    const sb = this.surveyService.generateUUID().subscribe((res) => {
      if (res) {
        this.isLoaderShowMovement = false;
        this.isLoaderShowMission = false;
        this.isLoaderShowCard = false;
        this.isLoaderShowSignupSub = false;
        if (this.isTraveller) {
          this.travelID = res.UUID;
          this.addTravelAnswer();
        } else {
          this.uuid = res.UUID;
          localStorage.setItem("UUID", res.UUID);
          this.router.navigate(["/survey/form/" + this.uuid]);
        }
      }
    });
    this.subscriptions.push(sb);
  }

  addTravelAnswer() {
    var travelData = {
      q_id: 1,
      q_text:
        "To kick off our relationship, please tell us which best describes you:",
      ans: "Eco-conscious traveler",
      option: "Traveller",
    };
    this.surveyService.addAnswer(travelData, this.travelID).subscribe(
      (res) => {
        this.router.navigate(["/survey/signup/" + this.travelID], {
          queryParams: { fromPage: "travel" },
        });
        localStorage.setItem("TravelUUID", this.travelID);
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Something Went Wrong!!",
        });
      }
    );
  }

  private getBlogsList() {
    this.blogsService.getBlogs(this.page, this.page_size).subscribe({
      next: (res) => {
        this.blogData = res.results;
        this.generateRandomValues();
      },
      error: (err) => {
        console.log("err :>> ", err);
      },
    });
  }

  private generateRandomValues() {
    const data = [...this.blogData];
    this.blogList = [];
    for (let i = 0; i < (this.isMobile ? 4 : 3); i++) {
      const randomIndex = Math.floor(Math.random() * data.length);
      if (data[randomIndex]) {
        this.blogList.push(data[randomIndex]);
        data.splice(randomIndex, 1); // Remove the picked element from the copy.
      }
    }
  }

  private getCompanyLogos() {
    this.commonService.getCompanyLogos().subscribe({
      next: (res: any) => {
        this.networksList = res;
      },
      error: (err: any) => {
        console.log("err :>> ", err);
      },
    });
  }

  public onShowDetails(id: number) {
    this.router.navigate([`blog/`, id]);
  }

  public onMouseEnter() {
    this.tooltipConfig.triggers = "manual";
    this.template.open();
  }
  public onMouseLeave() {
    this.template.close();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  public onSignup() {
    // this.vendorsService.signUp();
    this.router.navigate(['/auth/signup']);
  }
}
