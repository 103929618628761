import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { BlogDetails, BlogTypes, Resblogs, SubscribeData } from "../interface/blog";

@Injectable({
  providedIn: "root",
})
export class BlogsService {
  private url: string = environment.baseURL + "vendor-survey/";
  constructor(private http: HttpClient) {}

  getBlogsTypes(): Observable<BlogTypes[]> {
    return this.http.get<BlogTypes[]>(`${this.url}blog-type-list`);
  }

  getBlogs(page: number, page_size: number): Observable<Resblogs> {
    return this.http.get<Resblogs>(
      `${this.url}blog-list?page=${page}&page_size=${page_size}`
    );
  }

  getBlogDetails(id: number): Observable<BlogDetails> {
    return this.http.get<BlogDetails>(`${this.url}blog-detail/${id}/`);
  }

  getBlogFilter(
    blog_type: string | number,
    page: number,
    page_size: number
  ): Observable<Resblogs> {
    return this.http.get<Resblogs>(
      `${this.url}blog-list?page=${page}&blog_type=${blog_type}&page_size=${page_size}`
    );
  }

  postSubscription(data: SubscribeData): Observable<SubscribeData> {
    return this.http.post<SubscribeData>(
      `${environment.baseURL}contacts/blog-subscribe`,
      data
    );
  }
}
