import { CommonService } from 'src/app/services/common.service';
import { Component, OnInit } from '@angular/core';
import { icons } from 'src/assets/img/svg/svg';

@Component({
  selector: 'app-glance',
  templateUrl: './glance.component.html',
  styleUrls: ['./glance.component.scss']
})
export class GlanceComponent implements OnInit {
  icon = icons

  glanceList = [
    {
      id: 1,
      name: 'Hotels',
      counts: 0,
      key: 'hotels',
      icon: icons.hotels
    },
    {
      id: 2,
      name: 'Restaurants',
      key: 'restaurants',
      counts: 0,
      icon: icons.restaurants
    },
    {
      id: 3,
      name: 'Countries',
      key: 'countries',
      counts: 0,
      icon: icons.countries
    }
  ]


  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.commonService.getGlanceList().subscribe({
      next: (res: any) => {
        if (res.length) {
          this.glanceList = this.glanceList.map((resp) => {
            return {
              ...resp,
              counts: res[0][resp.key]
            }
          })
        }
      },
      error: (err: any) => {
        console.log("err :>> ", err);
      },
    });
  }

}
