import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { icons } from 'src/assets/img/svg/svg';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {

  public icons = icons;
  // @Input() message!:string;
  @Input() popUpMessage!:any;
  @Output() isClick = new EventEmitter<any>();
  @Output() closePopUp = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }


  onclick(){
    this.isClick.emit(true);
    if (typeof window !== "undefined") {
      window.open(this.popUpMessage.url,"_blank");
    }
  }

  onClose(){
    this.closePopUp.emit(true);
  }
  

}
