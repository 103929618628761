import { ViewportScroller } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router, Scroll } from "@angular/router";
import { filter } from "rxjs/operators";

// declare ga as a function to access the JS code in TS
declare let gtag: Function;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  public currentUrl: any;
  surveyTitle!: string;
  surveyDescription!: string;
  surveyImage!: string;
  public isVendor: boolean  = false;
  public isAboutus: boolean  = false;
  public logoImage!:string;
  public hide:boolean = false;

  constructor(
    public router: Router,
    private viewportScroller: ViewportScroller
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof Scroll) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

  ngOnInit() {
    this.setUpAnalytics();
  }

  setUpAnalytics() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.checkHeader();
        if (typeof gtag === 'function') {

          gtag("config", "G-HM197WLETD", {
            page_path: event.urlAfterRedirects,
          });
        }
      });
  }

  private checkHeader() {
    switch (true) {
      case this.router.url.includes("vendors/result"):
        case this.router.url.includes("vendors/dashboard"):
        case this.router.url.includes("payment"):
        this.isVendor = true;
        this.isAboutus = false;
        this.hide = false;
        break;
      case this.router.url.includes("about-us"):
        this.isVendor = false;
        this.isAboutus = true;
        this.hide = false;
        break;
      case this.router.url.includes("dashboard"):
      case this.router.url.includes("sustainability"):
      case this.router.url.includes("auth"): 
      case this.router.url.includes("/survey/submit/successContact"):
      case this.router.url.includes("/survey/dispute/thank-you"):
      case this.router.url.includes("/survey/submit/successPaperRequest"):
      case this.router.url.includes("vendors"):
        this.hide = true;
        break;
      default:
          this.isVendor = false;
          this.isAboutus = false; 
          this.hide = false;
        break;
    }
  }

  ngOnDestroy() {}
}
