<div id="cover-spin" *ngIf="isLoading"></div>
<section class="how how-font-width" id="greenaIndex">
  <div class="home-container">
    <div class="how-content" id="how">
      <div class="row glx-how-section">
        <!-- For vendor page  start-->
        <div class="col-lg-12">
          <div class="how-slider howslider-1 glx_slider">
            <div class="row mb-4">
              <div class="col-12 col-lg-10 col-xl-9">
                <h3 class="glx-header">
                  Greena Index in action:
                </h3>
              </div>
            </div>
            <div class="progress_slider">
              <owl-carousel-o [options]="progresSlider">
                <ng-template carouselSlide *ngFor="let data of activeShowRoomData;let i=index;">
                  <div class="how-slider-item">
                    <div class="how-slider-inner-wrap">
                      <div class="single-how-img">
                        <a class="inner-how-img">
                          <img *ngIf="browserType === 'Safari'; else elseBlock" [src]="data.profile_photo"
                            alt="Image" loading="lazy"/>
                          <ng-template #elseBlock> <img [src]="data.profile_photo" alt="Image" loading="lazy"/></ng-template>
                        </a>
                        <div class="how-meta">
                          <img src="{{ data.business.business_name ==='Hotel' ? 'assets/img/hotels.svg'
                          :'assets/img/restaurants.svg'}}" [alt]="data.business.business_name" loading="lazy"/>
                          <span>{{ data.business.business_name }}</span>
                        </div>
                      </div>
                      <div class="single-how-content single_content">
                        <h4>{{data.name_of_vendor}}</h4>
                        <div class="location"><i class="bi bi-geo-alt"></i> {{ data.location}}
                        </div>
                        <div class="single-how-rating border_bottom_how">
                          <div class="d-flex align-items-center">
                            <p class="heading mx-2">{{"Page.greena_index" | transloco}}</p>
                            <div class="tooltip-info d-inline-block align-middle">
                              <a [ngbTooltip]="tipContent" tooltipClass="custom-tooltip" container="body"
                                placement="bottom top-left top-right bottom-right bottom-left right" #t="ngbTooltip"
                                (mouseenter)="onMouseEnter(t)" triggers="click:blur"><i
                                  [innerHTML]="icon['questionIcon'] | safe"></i>
                              </a>
                              <ng-template #tipContent>
                                <div class="tooltip-wrap" (mouseleave)="onMouseLeave(t)">
                                  <i [innerHTML]="icon['info'] | safe"></i>
                                  <div class="tooltip-content">
                                    <p class="info_icon how-section-content mb-2">
                                      The Greena Index indicates the level of positive impact a hotel or restaurant has on the environment, local community, and the well-being of its guests.
                                    </p>
                                  </div>
                                </div>
                              </ng-template>  
                            </div>
                          </div>
                          <span class="float-end greena-index-size">{{data.greena_index}}/100</span>
                        </div>
                        <div class="slider-circel-bar">
                          <div class="progress-box">
                            <div class="percent">
                              <svg>
                                <circle [attr.cx]="cxValue" [attr.cy]="cyValue" [attr.r]="radiusValue"></circle>
                                <circle [attr.cx]="cxValue" [attr.cy]="cyValue" [attr.r]="radiusValue"
                                  [style.--percent]="data.environmental"></circle>
                              </svg>
                              <div class="number">
                                <h3>{{ data.environmental | number: "1.0-0" }}</h3>
                              </div>
                            </div>
                            <span class="sector">Environmental</span>
                          </div>
                          <div class="progress-box">
                            <div class="percent">
                              <svg>
                                <circle [attr.cx]="cxValue" [attr.cy]="cyValue" [attr.r]="radiusValue"></circle>
                                <circle [attr.cx]="cxValue" [attr.cy]="cyValue" [attr.r]="radiusValue"
                                  [style.--percent]="data.social"></circle>
                              </svg>
                              <div class="number">
                                <h3>{{ data.social | number: "1.0-0" }}</h3>
                              </div>
                            </div>
                            <span class="sector">Social</span>
                          </div>
                          <div class="progress-box">
                            <div class="percent">
                              <svg>
                                <circle [attr.cx]="cxValue" [attr.cy]="cyValue" [attr.r]="radiusValue"></circle>
                                <circle [attr.cx]="cxValue" [attr.cy]="cyValue" [attr.r]="radiusValue"
                                  [style.--percent]="data.health"></circle>
                              </svg>
                              <div class="number">
                                <h3>{{ data.health | number: "1.0-0" }}</h3>
                              </div>
                            </div>
                            <span class="sector">Well-being</span>
                          </div>
                        </div>
                        <div class="single-how-feachers single_feacher">
                          <h5>Top features:</h5>
                          <ul class="list-inline mb-0">
                            <li class="list-inline-item" *ngFor="let feature of data.features">
                              <i class="bi bi-check-circle"></i>
                              &nbsp;{{feature.name}}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <a [href]="data.vendor_link" target="_blank"
                      class="single-how-location single_location text-center">
                      <span>
                        Learn more
                      </span>
                      <i class="bi bi-arrow-right-short"></i></a>
                  </div>
                </ng-template>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
      <!-- For vendor page  end-->
    </div>
  </div>
</section>