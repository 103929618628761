import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ContactUsComponent } from "./contact-us.component";
import { ToastModule } from "primeng/toast";
@NgModule({
  declarations: [ContactUsComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ToastModule],
  exports: [ContactUsComponent],
})
export class ContactUsModule {}
