import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError as observableThrowError } from "rxjs";
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  baseUrl: any = environment.baseURL;
  public location = new BehaviorSubject<any>({});
  constructor(private http: HttpClient,private router:Router) {
  }

  private handleError(error: HttpErrorResponse) {
    return observableThrowError(error || "Server error");
  }

  getShowRoom(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "show-room/get-show-room-data/").pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getLocation(): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + "vendor-survey/getipwisecountry/").pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }
  
  getCompanyLogos():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}setting/customer-logo/`);
  }

  scrollTop(scrollId: string = "") {
    if(typeof document !== "undefined"){
      const data: HTMLElement | null = document.getElementById(scrollId);
      setTimeout(() => {
        data?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 100);
    }
  }

  public onGoto(data: string) {
    switch (data) {
      case "about-us":
        this.router.navigate(["/about-us"]);
        break;
      case "greenaIndex":
        window.open("/greena-index","_blank");
        break;
      case "verification":
        window.open("/verification","_blank");
        break;
    }
  }

  getPersonalised(id: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}setting/personalised-sales/${id}/`
    );
  }

  gotoNewTab(url:string){
    if(typeof window !== "undefined"){
      window.open(url,"_blank");
    }
  }

  getGlanceList():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}setting/quick-numbers/`);
  }

}
