<section class="organization-section" *ngIf="isLoaded">
  <div class="container">
    <div class="orag-inner-wrap">
      <div class="org-header text-center">
        <i [innerHTML]="icon['mountainTop'] | safe"></i>
        <h2>Join our network of sustainable <br class="d-none d-sm-block" /> tourism companies!</h2>
      </div>

      <div class="orag-logo-wrap">
        <owl-carousel-o [options]="logoSlider">
          <ng-template carouselSlide *ngFor="let network of networksList">
            <div class="orag-logo-inner-wrap">
              <img [src]="network.logo" alt="logo" loading="lazy">
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>

    </div>
    <button type="button" class="btn btn-white orag-btn w-100 justify-content-center" (click)="onSignup()">
      Join us now!
      <i [innerHTML]="icon['arrowLong'] | safe"></i>
    </button>
  </div>
</section>