import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CarouselModule } from "ngx-owl-carousel-o";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { ContactUsModule } from "../contact-us/contact-us.module";
import { TranslocoRootModule } from "../transloco-root.module";
import { TooltipModule } from "primeng/tooltip";
import { DialogModule } from "primeng/dialog";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { HowSectionsComponent } from "./how-sections/how-sections.component";
import { ToastModule } from "primeng/toast";
import { OrganizationComponent } from "./organization/organization.component";
import { OrderByPipe } from '../shared/pipe/order-by.pipe';
import { ProgressBarModule } from "primeng/progressbar";
import { PipeModule } from "../shared/pipe/pipe.module";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    HowSectionsComponent,
    OrganizationComponent,
    OrderByPipe
  ],
  imports: [
    CommonModule,
    CarouselModule,
    TooltipModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    DialogModule,
    ContactUsModule,
    ToastModule,
    ProgressBarModule,
    PipeModule,
    NgbTooltipModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    HowSectionsComponent,
    OrganizationComponent,
    OrderByPipe
  ],
})
export class CoreModule {}
