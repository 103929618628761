<footer class="footer bg-primary">
  <div class="container">
    <div class="footer-row">
      <div class="content-box-wrap footer-logo-wrap">
        <a routerLink="/">
          <img src="assets/img/logo-white.svg" alt="Greena World" loading="lazy"/>
        </a>
        <p class="logo-desc">
          We use technology to make tourism <br class="d-none d-md-block" />
          sustainable.
        </p>
        <div class="social-icon">
          <a [href]="linkedin" target="_blank" aria-level="linkedin"
            ><i [innerHTML]="icon['linkdin'] | safe"></i
          ></a>
          <a [href]="twitter" target="_blank" aria-level="twitter"
            ><i [innerHTML]="icon['twitter'] | safe"></i
          ></a>
          <a [href]="facebook" target="_blank" aria-level="facebook"
            ><i [innerHTML]="icon['facebook'] | safe"></i
          ></a>
          <a [href]="instragram" target="_blank" aria-level="instragram"
            ><i [innerHTML]="icon['instagram'] | safe"></i
          ></a>
        </div>
      </div>
      <div class="content-box-wrap" *ngFor="let data of footerDatas">
        <h4 class="content-title">{{ data.title }}</h4>
        <ul class="menu-list">
          <li *ngFor="let option of data.option">
            <a [href]="option.link" target="_blank">{{ option.lable }}</a>
          </li>
        </ul>
      </div>
      <div class="content-box-wrap">
        <h4 class="content-title">Contact</h4>
        <div class="contact-info">
          <a (click)="open()"><i [innerHTML]="icon['contact_form'] | safe"></i
            ><span>Get support</span></a
          >
          <a href=""
            ><i [innerHTML]="icon['pin'] | safe"></i
            ><span
              >Kemp House, 128 City Road, London, EC1V 2NX, United Kingdom</span
            ></a
          >
        </div>
      </div>
    </div>
    <div class="footer-bottom-row">
      <p>
        <i [innerHTML]="icon['copyrights'] | safe"></i> All rights reserved by
        Greena World ™, {{currentYear}}.
      </p>
      <div class="privacy-terms">
        <a routerLink="/terms-condition">Terms & Conditions</a><span>|</span>
        <a routerLink="/privacy-policy">Privacy & Cookie Policy</a> 
      </div>
    </div>
  </div>
</footer>
<p-dialog
  header="To contact us, please fill in the form below:"
  [(visible)]="displayContact"
  [modal]="true"
  [style]="{ width: '713px' }"
  [draggable]="false"
  class="contact_popup"
  (onHide)="close()"
  [closeOnEscape]="false"
>
  <app-contact-us></app-contact-us>
</p-dialog>
